const Login = () =>
  import(/* webpackChunkName: "login" */ "../views/Login.vue");
const Regster = () =>
  import(/* webpackChunkName: "regster" */ "../views/Regster.vue");

const Newpassword = () =>
  import(/* webpackChunkName: "newpassword" */ "../views/NewPassword.vue");
const Resetpassword = () =>
  import(/* webpackChunkName: "resetpassword" */ "../views/ResetPassword.vue");

export const authRoutes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    /*     meta: {
        middleware: log,
      }, */
  },
  {
    path: "/regster",
    name: "regster",
    component: Regster,
  },
  {
    path: "/newpassword",
    name: "newpassword",
    component: Newpassword,
  },
  {
    path: "/resetpassword",
    name: "resetpassword",
    component: Resetpassword,
  },
];
