<template>
  <div id="PricingTables">
    <b-container>
      <div class="mainTitle">
        <p>Our Most Popular Hosting</p>
        <h2>Our Packages</h2>
      </div>
      <b-row cols-xl="3" cols-lg="3" cols-md="2" cols-sm="1" cols="1">
        <b-col>
          <div  class="price">Starting at 3,150 LYD / 3 months</div>
          <div class="table">
            <div class="table-imge">
              <img src="../assets/plan-img1.png" alt="" />
            </div>
            <div class="table-content">
              <h4>Shared Elastic Cloud</h4>
              <p>
                Get a quick and cost-effective hosting option that's easy to use
                - even if you're a total newbie.
              </p>
              <br />
              <ul>
                <li><p>vCPU: 8 (up to 64)</p></li>
                <li>RAM (GB) : 32 (up to 256)</li>
                <li>Storage : 250GB (up to 2TB)</li>
                <li>100% Uptime</li>
              </ul>
              <br />
              <!-- <div class="buttons">
                <a class="btn btn-green btn-medium" href="">More Details</a>
              </div> -->
            </div>
          </div>
        </b-col>
        
        <b-col
          ><div class="price-gold">Starting at 390 LYD / 3 months</div>
          <div class="table">
            <div class="table-imge">
              <img src="../assets/plan-img2.png"  alt="" />
            </div>
            <div class="table-content">
              <h4>VPS Hosting</h4>
              <p>
                Looking for a private & poweful server with customizable specs? Welcome to 1stVault VPS
              </p>
              <br />
              <ul>
                 <li><p>vCPU: 2 (up to 8) </p></li> <!--I really wanna write upto :(-->
                <li>RAM (GB) : 8 (up to 32)</li>
                <li>Storage (GB) : 100 (up to 500)</li>
                <li>100% Uptime</li>
              </ul>
              <br />
              <!-- <div class="buttons">
                <a class="btn btn-green btn-medium" href="">More Details</a>
              </div> -->
            </div>
          </div></b-col>
          <b-col>
          <div class="price">Starting at 600 LYD / 3 months</div>
          <div class="table">
            <div class="table-imge">
              <img src="../assets/plan-img3.png" alt="" />
            </div>
            <div class="table-content">
              <h4>Cloud Storage</h4>
              <p>
                Get a quick and cost-effective hosting option that's easy to use
                - even if you're a total newbie.
              </p>
              <br />
              <ul>
                <li>Size : 1TB (up to 20TB)</li>
                <li>Data Safety</li>
                <li>100% Uptime</li>
              </ul>
              <br />
              <p class="note">- Can be sold separately or as an addition to VPS </p>
              <!-- <div class="buttons">
                <a class="btn btn-green btn-medium" href="">More Details</a>
              </div> -->
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div class="TripleCols">
      <b-container>
        <b-row cols-xl="3" cols-lg="3" cols-md="3" cols-sm="2" cols="1">
          <!-- <b-col>
            <div class="cols">
              <i class="fa fa-undo pull-left icon"></i>
              <span class="colsTitle">
                <h4>Daily backups</h4>
              </span>
            </div>
          </b-col> -->
          <b-col>
            <div class="cols">
              <i class="fa fa-hdd pull-left icon"></i>
              <span class="colsTitle">
                <h4>Free migrations</h4>
              </span>
            </div>
          </b-col>
          <b-col>
            <div class="cols">
              <i class="fa fa-rocket pull-left icon"></i>
              <span class="colsTitle">
                <h4>Staging environments</h4>
              </span>
            </div></b-col
          >
          <b-col>
            <div class="cols">
              <i class="fa fa-undo pull-left icon"></i>
              <span class="colsTitle">
                <h4>Daily backups</h4>
              </span>
            </div></b-col
          >
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@700&display=swap");
#PricingTables {
  background-color: #0e0b2b;

  .mainTitle {
    padding: 60px 0;
    margin: 0 auto;
    p {
      font-size: 16px;
      color: #718096;
      font-family: "Montserrat", sans-serif !important;
      text-align: center;
    }
    h2 {
      font-size: 36px;
      font-weight: 700;
      font-family: "Overpass", sans-serif;
      text-align: center;
      color: blanchedalmond;
    }
  }
  .price {
    background-color: #253152;
    color: #cbbcdf;
    max-width: 370px;
    width: 290px;
    font-size: 14px;
    border-radius: 20px;
    padding: 6px 6px 24px;
    letter-spacing: 1px;
    text-align: center;
    margin: 0 auto -18px;
  }
  .price-gold {
    background-color: #2ec1ff;
    color: #fff;
    max-width: 370px;
    width: 290px;
    font-size: 14px;
    border-radius: 20px;
    padding: 6px 6px 24px;
    letter-spacing: 1px;
    text-align: center;
    margin: 0 auto -18px;
  }
  .table {
    border-radius: 20px;
    background-color: #ecf0fc;
    border: 1px solid rgba(255, 255, 255, 0.01);
    padding: 20px;
    width: auto;
    text-align: center;
    margin: 0 5px 70px 5px;
    box-shadow: 0px 10px 15px #918f8f;
    max-width: 100%;
    .table-imge {
      img {
        width: 40%;
        margin-bottom: 10px;
      }
    }

    .table-content {
      border-top: none;
      h4 {
        font-size: 21px;
        font-weight: 600;
        padding-top: 10px;
        color: #152c5b;
        font-family: "Montserrat", sans-serif !important;
      }
      p {
        font-size: 16px;
        color: #718096;
        transition: 0.2s linear;
        font-family: "Open Sans";
        margin-bottom: 0;
      }
      ul {
        font-size: 15px;
        line-height: 26px;
        padding: 0;
        li {
          text-align: center;
          list-style: none;
          border-bottom: 1px solid #e6e7e9;
          padding: 13px 0 12px;
          font-size: 16px;
          color: #718096;
          transition: 0.2s linear;
          font-family: "Open Sans";
          margin-bottom: 0;
        }
      }
      .buttons {
        a {
          display: block;
          width: 90%;
          margin: 0 auto;
          color: blanchedalmond;
          border-radius: 4px;
          padding: 10px 40px;
          font-size: 16px;
          border-radius: 2px;
          position: relative;
          bottom: 8px;
          background-color: #8d3de2;
          font-family: "Open Sans";
        }
        a:hover {
          background-color: #3f0c74;
        }
      }
    }
  }
  .TripleCols {
    padding-bottom: 60px;
    padding-top: 60px;
    background: #202c4d;
    .cols {
      display: flex;
      justify-content: center;
      align-items: baseline;
      .icon {
        font-size: 20px;
        margin: 2px 7px 0 0;
        color: #ffbe3d;
        border: 1px solid rgba(206, 146, 77, 0.08);
        text-align: center;
        border-radius: 7px;

        transition: 0.1s linear;
      }
      .colsTitle {
        margin-bottom: 2px;
        h4 {
          font-family: "Montserrat", sans-serif !important;
          color: #dee5f7;
          margin-top: 0;
          font-weight: 600;
          font-size: 18px;
          letter-spacing: 1px;
        }
      }
      .note {
        margin: 12px !important;
        font-weight: bolder;
        font-stretch: expanded;
      }
    }
  }
}
</style>
