import App from "./components/App.vue";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import Vuelidate from "vuelidate";
import "normalize.css";
import 'animate.css';
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { authenticationStore } from "../../authentication/store/index";

export class CoreModule {
 constructor(router, store, i18n) {
  this.router = router;
  this.store = store;
  this.i18n = i18n;
  this.name = "core";
  /** @type {import('vue').default} */
  this.app = null;
 }
 install(Vue) {
  Vue.use(Vuelidate);
  Vue.use(BootstrapVue);
  Vue.use(Vuesax, {});
  Vue.use(IconsPlugin);
  Vue.config.productionTip = false;

  authenticationStore.actions.getToken(authenticationStore);
  this.app = new Vue({
   router: this.router,
   store: this.store,
   i18n: this.i18n,
   render: (h) => h(App),
  }).$mount("#app");
 }
}
