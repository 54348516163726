<template>
  <div id="PrivacyPolicy">
   <Navigation/>
    <div class="headerPrivacyPolicy">
      <b-container>
        <b-row>
          <b-col lg="6" md="12">
            <p>1stVault.</p>
            <h2>Terms of Servicey</h2>
          </b-col>
          <b-col lg="6" md="12"
            ><img src="../assets/privacy.png" alt="about"
          /></b-col>
        </b-row>
      </b-container>
    </div>
    <div class="colorfulBoxesPrivacyPolicy">
      <b-container>
        <div class="title">
          <h2>Terms of Service</h2>
        </div>
        <b-row>
          <b-col>
            <div class="box-container">
              <h4>General information</h4>
              <p>
                Use of SaharaCloud's Service constitutes acceptance and
                agreement to SaharaCloud's AUP (Acceptable Use Policy) as well
                as SaharaCloud's TOS (Terms of Service). From herewith in this
                TOS, AUP, and SLA, the usage of "us", "we", "our", "ours" shall
                constitute reference to SaharaCloud, the usage of "you", "your",
                "they", "them" shall refer to client / customer of SaharaCloud.
              </p>
              <br />
              <h4>Acceptance of Contractual Agreement</h4>
              <p>
                Client agrees that by placing an order either by means of
                electronic ordering (web order form) or submitting a written
                contract, and receipt of such order by SaharaCloud, that you are
                agreeing to our TOS, AUP, and SLA. No Modifications of said
                contract by customer is allowed. 2. SaharaCloud will provide,
                and Customer will purchase and pay for, the Services, and
                service fees specified in the Order for the applicable Service
                Description. Customer acknowledges that the service, and service
                fees have been communicated to the customer, and that they are
                aware of all applicable charges as per contract, AUP, SLA, and
                TOS.Customer also understands that any promotional offers unless
                specified in contractual terms will not be applicable to their
                individual service.
              </p>
              <br />
              <h4>What may NOT be hosted on our network</h4>
              <p>
                Warez IRC Child pornography Copyrighted media, includes but not
                limited to: Video, Music, TV, Radio Hacking tools / material
                Anything that may harm us, the server, or anyone else (mentally,
                physically) Network Uptime is the total time in a calendar month
                that SaharaCloud network is available through the Internet,
                provided that Client has established connectivity. SaharaCloud
                takes responsibility for Network availability within their
                network, however, we cannot be held liable for upstream
                problems, outside of our network. Our guarantee is that our
                Network will be available to clients free of Network Outages,
                that render 100% packet loss, 99% of each calendar month.
                Network Outages or Unscheduled Downtime is any unplanned or
                unscheduled interruption in Service availability during which
                Client is unable to access the services as described in the
                section titled "Network Uptime" above. A Network Outage is
                defined as a period in which 100% packet loss to our network is
                experienced, which is determined to have been caused by a
                problem in SaharaCloud's Network as confirmed by SaharaCloud.
                Downtime or outages are measured as the total length of time of
                the unplanned interruption in service availability in a calendar
                month. Scheduled Downtime is any SaharaCloud scheduled
                interruption of Services, for the purpose of network upgrades,
                or replacement of any equipment in order to provide for you
                better service. Scheduled downtime occurs during notified
                downtime periods, with as much advance warning as possible via
                e-mail with a minimum of 12- 24 hours notice.
              </p>
              <br />
              <h4>To whom will you disclose Personal Information?</h4>
              <p>
                Where possible, we will inform you, at or before the time of
                collecting your personal information, the types of organisations
                that we intend to disclose your personal information to.
                Sometimes we are required or authorised by law to disclose your
                personal information. Circumstances in which we may disclose
                your personal information would be to a Court or Tribunal in
                response to a request or in response to a subpoena. We may
                collect details from or disclose your personal information to
                credit or fraud agencies or debt recovery agencies for
                verification or debt collection purposes. When required, we may
                disclose your personal information to other organisations for
                the purpose of fulfilling a your service request. We may also
                disclose your personal information to our professional advisors,
                including our accountants, auditors and lawyers, or to other
                organisations involved in sale or transfer of our assets.
              </p>
              <br />
              <h4>Direct Marketing</h4>
              <p>
                From time to time we may use your personal information to
                provide you with current information about our services, special
                offers you may find of interest, changes to our organisation, or
                new products or services being offered by us or any company we
                are associated with, seminars, and conventions. If you do not
                wish to receive marketing information, you may at any time
                decline to receive such information by emailing. We will not
                charge you for giving effect to your request and will take all
                reasonable steps to meet your request at the earliest possible
                opportunity.
              </p>
              <br />
              <h4>Performance Credit exclusions</h4>
              <p>
                The following are excluded from the monthly calculation of
                Service Availability: Scheduled downtime. Problems outside of
                SaharaCloud's network (upstream providers, or client's inbound
                connection) not effecting 100% loss to our network.
                Interruptions or failure of individual service caused by client,
                their employees, client's customers, etc. to their service.These
                include inaccurate configuration, 3rd party software, client
                abuse or over utilization of resources, hacked servers, attacks,
                exploits, server hardware failures or other users on server to
                cause the server to overload which may cause downtime. We will
                do our best to remove such offenders immediately.
              </p>
              <br />
              <h4>Performance Credit exclusions</h4>
              <p>
                Payment is due every 6 or 12 months by credit card or Tadawel or
                Sadad, an invoice will be raised and can be paid from the Client
                Area, please note that service may be suspended on accounts that
                reach 10 days past due, We do not charge late or re-connection
                fees.
              </p>
              <h4>Delinquent Accounts</h4>
              <p>
                SaharaCloud may temporarily deny service or terminate this
                Agreement upon failure of Customer to pay charges when due. Such
                termination or denial will not relieve Customer of
                responsibility for the payment of all accrued service fees, or
                any collection fees.
              </p>
              <br />
              <h4>Account Cancellation</h4>
              <p>
                All requests for canceling any service / services must be made
                in writing with at least 15 days notice but not more than 7 days
                prior written via email to include your domain name, last name
                on credit card in your email.
              </p>
              <br />
              <h4>Refunds and Disputes</h4>
              <p>
                All payments to SaharaCloud are non refundable (unless covered
                by 30-day Money Back Guarantee). This includes any setup fees
                and monthly fees regardless of usage. All billing disputes must
                be reported within 30 days of the time the dispute occurred.
                Disputed charges to your credit card issuer, also known as
                charge backs, in SaharaCloud's discretion which is valid, under
                the terms and conditions of our SLA, AUP, and TOS, will result
                in service interruption, and reconnection fees to restore the
                desired service.
              </p>
              <br />
              <h4>30 Day Money Back Guarantee</h4>
              <p>
                All hosting plans come with a 30 day money guarantee. If for
                whatever reason we fail to satisfy your needs and are within 30
                days we will fully refund your money all we ask is you give us a
                reason so this will help us improve as a company, we will not
                deduct ANY fee.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <Partners />
    <Footer />
  </div>
</template>

<script>

import Footer from "../components/Footer.vue";
import Partners from "../components/Partners.vue";
import Navigation from "../components/Navigation.vue";
export default {
  name: "TermsOfService",
  components: { Footer, Partners, Navigation },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@700&display=swap");
#PrivacyPolicy {
  .headerPrivacyPolicy {
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #0e0b2b;
    h2 {
      padding: 0 0 30px 0;
      font-weight: 800;
      font-size: 40px;
      color: #008aff;
      font-family: "Montserrat", sans-serif !important;
      letter-spacing: 1px;
    }
    p {
      margin-top: 150px;
      font-size: 17px;
      line-height: 26px;
      font-family: "Montserrat", sans-serif !important;
      transition: 0.2s linear;
      color: blanchedalmond;
    }
    img {
      width: 100%;
    }
  }
  .headerPrivacyPolicy::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background-color: #ffffff;
    clip-path: polygon(100% 69%, 0 102%, 103% 100%);
  }
  .colorfulBoxesPrivacyPolicy {
    position: relative;
    background-color: #fff;
    padding: 70px 0 70px 0;
    .title {
      text-align: center;
      padding-bottom: 60px;
      h2 {
        color: #1c2746;
        font-size: 36px;
        font-weight: 700;
        font-family: "Overpass", sans-serif;
      }
    }
    .box-container {
      text-align: left;
      padding: 0 40px 50px;
      border-radius: 15px;
      margin: 0 15px;
      font-family: "Montserrat", sans-serif !important;
      h4 {
        margin-top: 0;
        font-family: "Overpass";
        font-size: 23px;
        font-weight: 600;
        margin-bottom: 15px;
        color: #1c2746;
      }
      p {
        color: #1c2746;
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
  .colorfulBoxesPrivacyPolicy::before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 200px;
    background-color: #0f0e2b;
    clip-path: polygon(100% 69%, 0 100%, 100% 100%);
  }
}
</style>
