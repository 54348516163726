<template>
 <!-- =======================================================
  * Website Name: STVAULT CLOUD - v1.0.0
  * Website URL: https://1stVault.com
  * Author: abd alshafie almajdoup
  ======================================================== -->
 <div id="app">
  <router-view />
  <ScrollUp />
 </div>
</template>
<script>
import "vue-scroll-up/dist/style.css";
import ScrollUp from "vue-scroll-up";
export default {
 components: {
  ScrollUp,
 },
 methods: {},
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

#app {
 font-family: "Montserrat", sans-serif !important;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 color: blanchedalmond;
 .vue-scroll-up {
height: 47px;
    width: 41px;
  background-color: #551d91;
  border-radius: 3px;
  &::after {
   width: 16px;
   border: 3px solid #fff;
  }
  &::before {
   width: 2px;
  }
 }
}
</style>
