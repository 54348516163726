import { authRoutes } from "./router/auth-routes";
import { authenticationStore } from "./store";

export class AuthenticationModule {
  constructor(router, store) {
    this.router = router;
    this.store = store;
    this.name = "authentication";
  }

  install(/*Vue*/) {
    // this.router.addRoutes(regsterRoutes);
    for (let authRoute of authRoutes) {
      this.router.addRoute(authRoute);
    }
    this.store.registerModule([this.name], authenticationStore);
  }
}
