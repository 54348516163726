<template>
 <div id="Partners">
  <b-container>
   <b-row cols-lg="6" cols-md="4" cols-sm="2" cols="1">
    <b-col> <img src="../assets/partners/cloudflare.png" alt="" /></b-col>
    <b-col> <img src="../assets/partners/cloudlinux.png" alt="" /></b-col>
    <b-col> <img src="../assets/partners/cpanel.png" alt="" /></b-col>
    <b-col> <img src="../assets/partners/dedicated.png" alt="" /></b-col>
    <b-col> <img src="../assets/partners/letsencrypt-logo.png" alt="" /></b-col>
    <b-col> <img style="width:70px" src="../assets/partners/huawei.png" alt="" /></b-col>
   </b-row>
  </b-container>
 </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#Partners {
 background-color: #0f0e2b;
 padding: 19px 0 19px 0;
 padding-bottom: 14px;
 border-bottom: 1px solid rgba(255, 255, 255, 0.1);

 img {
  width: 70%;
  height: 70px;
 }
}
</style>
