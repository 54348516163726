import I18n from "vue-i18n";

const defaultLocale = "en";

export class I18nModule {
  static name = "i18n";

  install(Vue) {
    Vue.use(I18n);

    this.i18n = new I18n({
      messages: {},
      locale: defaultLocale,
      fallbackLocale: defaultLocale,
    });
  }
}
