import Vue from "vue";
import {
 RouterModule,
 MiddlewareModule,
 StoreModule,
 CoreModule,
 I18nModule,
 PluginsModule,
} from "@/modules/plugins";
import { HomeModule } from "@/modules/home";

import { AuthenticationModule } from "@/modules/authentication";
import { ProductsCategoresModule } from "@/modules/productsCategores";
import { CartModule } from "@/modules/cart";
import { WalletModule } from "@/modules/wallet";

function bootstrap() {
 const i18nModule = new I18nModule();
 i18nModule.install(Vue);

 const routerModule = new RouterModule();
 routerModule.install(Vue);

 const storeModule = new StoreModule();
 storeModule.install(Vue);

 // plugen
 const middlewareModule = new MiddlewareModule(
  routerModule.router,
  storeModule.store
 );
 middlewareModule.install(Vue);

 const coreModule = new CoreModule(
  routerModule.router,
  storeModule.store,
  i18nModule.i18n
 );
 coreModule.install(Vue);

 // use plugens in Modules
 PluginsModule.install(
  coreModule.app,
  routerModule.router,
  storeModule.store,
  i18nModule.i18n
 );

 const homeModule = new HomeModule(
  routerModule.router,
  storeModule.store,
  i18nModule.i18n
 );
 homeModule.install(Vue);


 const authenticationModule = new AuthenticationModule(
  routerModule.router,
  storeModule.store,
  i18nModule.i18n
 );
 authenticationModule.install(Vue);

 const productsCategoresModule = new ProductsCategoresModule(
  routerModule.router,
  storeModule.store,
  i18nModule.i18n
 );
 productsCategoresModule.install(Vue);

 const cartModule = new CartModule(
  routerModule.router,
  storeModule.store,
  i18nModule.i18n
 );
 cartModule.install(Vue);

 const walletModule = new WalletModule(
  routerModule.router,
  storeModule.store,
  i18nModule.i18n
 );
 walletModule.install(Vue);
}

bootstrap();
