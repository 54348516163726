const Wallet = () => import(/* webpackChunkName: "wallet" */ "../views/Wallet.vue");
const Invoice = () => import(/* webpackChunkName: "invoice" */ "../views/Invoice.vue");

export const walletRoutes = [
 {
  path: "/wallet",
  name: "wallet",
  component: Wallet,
 },
 {
  path:"/Invoice",
  name:"Invoice",
  component:Invoice
 },
];
