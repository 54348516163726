import { productsCategoresRoutes } from "./router/productsCategores-routes";
import { productsCategoresStore } from "./store";

export class ProductsCategoresModule {
  constructor(router, store) {
    this.router = router;
    this.store = store;
    this.name = "productsCategores";
  }

  install() {
    // this.router.addRoutes(regsterRoutes);
    for (let productsCategoresRoute of productsCategoresRoutes) {
      this.router.addRoute(productsCategoresRoute);
    }
    this.store.registerModule([this.name], productsCategoresStore);
  }
}
