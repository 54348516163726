import axios from "@/plugin/axios";
import { mutations } from "./mutations";
export const actions = {
  getToken() {
    return new Promise((resolve, reject) => {
      axios({ url: "/token", method: "GET" })
        .then((response) => {
          const token = response.data.token;
          const user = response.data.user;
          let data = { token, user };
          mutations.assignToken(data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logout() {
    return new Promise((resolve, reject) => {
      axios({ url: "/logout", method: "POST" })
        .then((response) => {
          mutations.logout();
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /*  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      axios({ url: "http://localhost:8000/login", data: user, method: "POST" })
        .then((resp) => {
          const token = resp.data.token;
          const user = resp.data.user;
          localStorage.setItem("token", token);
          axios.defaults.headers.common["Authorization"] = token;
          commit("auth_success", token, user);
          resolve(resp);
        })
        .catch((err) => {
          commit("auth_error");
          localStorage.removeItem("token");
          reject(err);
        });
    });
  }, */
  /* 
  register({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      axios({
        url: "http://localhost:3000/register",
        data: user,
        method: "POST",
      })
        .then((resp) => {
          const token = resp.data.token;
          const user = resp.data.user;
          localStorage.setItem("token", token);
          axios.defaults.headers.common["Authorization"] = token;
          commit("auth_success", token, user);
          resolve(resp);
        })
        .catch((err) => {
          commit("auth_error", err);
          localStorage.removeItem("token");
          reject(err);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      commit("logout");
      localStorage.removeItem("token");
      delete axios.defaults.headers.common["Authorization"];
      resolve();
    });
  }, */
};
