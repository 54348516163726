<template>
    <div class="home">
        <Navigation />
        <Hero />
        <!-- <DomainSearchSection /> -->
        <WhyChooseUs />
        <Pricengtables />
        <FeaturesStyle />
        <BoxesStyleOne />
        <Testimonials />
        <Partners />
        <Footer />
    </div>
</template>

<script>
import Hero from '../components/Hero.vue'
// import DomainSearchSection from '../components/DomainSearchSection.vue'
import WhyChooseUs from '../components/WhyChooseUs.vue'
import Pricengtables from '../components/Pricengtables.vue'
import FeaturesStyle from '../components/FeaturesStyle.vue'
import BoxesStyleOne from '../components/BoxesStyleOne.vue'
import Testimonials from '../components/Testimonials.vue'
import Partners from '../components/Partners.vue'
import Footer from '../components/Footer.vue'
import Navigation from '../components/Navigation.vue'
export default {
    name: 'Home',

    components: {
        Hero,
        // DomainSearchSection,
        WhyChooseUs,
        Pricengtables,
        FeaturesStyle,
        BoxesStyleOne,
        Testimonials,
        Partners,
        Footer,
        Navigation,
    },
    methods: {},
}
</script>

<style scoped></style>
