import { homeRoutes } from "./router/home-routes";
import { homeStore } from "./store";
const loadLocaleMessages = () => {
 const locales = require.context("./lang/", false, /[A-Za-z0-9-_,\s]+\.js$/i);
 const messages = {};
 locales.keys().forEach((key) => {
  const matched = key.match(/([A-Za-z0-9-_]+)\./i);
  if (matched && matched.length > 1) {
   const locale = matched[1];
   messages[locale] = locales(key)?.default;
  }
 });
 // console.log(messages);
 return messages;
};

export class HomeModule {
 constructor(router, store,i18n) {
  this.router = router;
  this.store = store;
  this.i18n = i18n;
  this.name = "home";
 }

 install(/*Vue*/) {
  // this.router.addRoutes(homeRoutes);
  for (let homeRoute of homeRoutes) {
   this.router.addRoute(homeRoute);
  }
  this.store.registerModule([this.name], homeStore);
  const langs = loadLocaleMessages();
  for (const [key, value] of Object.entries(langs)) {
   const messages = value || {};
   this.i18n.mergeLocaleMessage(key, messages);
  }
 }
}
