<template>
  <div id="footer">
    <!--<b-container>
      <b-row>
         <b-col xl="3" lg="3" md="6">
          <div class="box-links">
            <h4>Services</h4>
            <div class="list-links">
              <ul>
                <li>
                  <i class="fas fa-chevron-right"></i>
                  <a href="">Shared Hosting</a>
                </li>
                <li>
                  <i class="fas fa-chevron-right"></i
                  ><a href="">Reseller Hosting</a>
                </li>
                <li>
                  <i class="fas fa-chevron-right"></i>
                  <a href="">Vps Hosting</a>
                </li>
                <li>
                  <i class="fas fa-chevron-right"></i><a href="">Windows VPS</a>
                </li>
                <li>
                  <i class="fas fa-chevron-right"></i
                  ><a href="">Dedicated Services</a>
                </li>
                <li>
                  <i class="fas fa-chevron-right"></i><a href="">Domain Name</a>
                </li>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col xl="3" lg="3" md="6">
          <div class="box-links">
            <h4>COMPANY</h4>
            <div class="list-links">
              <ul>
                <li>
                  <i class="fas fa-chevron-right"></i><a href="">About</a>
                </li>
                <li>
                  <i class="fas fa-chevron-right"></i><a href="">Contact</a>
                </li>
                <li>
                  <i class="fas fa-chevron-right"></i><a href="">Forum</a>
                </li>
                <li>
                  <i class="fas fa-chevron-right"></i
                  ><a href="">Terms of Services</a>
                </li>
                <li>
                  <i class="fas fa-chevron-right"></i
                  ><a href="">Privacy Policy</a>
                </li>
                <li><i class="fas fa-chevron-right"></i><a href="">FAQ</a></li>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col xl="3" lg="3" md="6">
          <div class="box-links">
            <h4>SOLUTIONS</h4>
            <div class="list-links">
              <ul>
                <li>
                  <i class="fas fa-chevron-right"></i><a href="">Login</a>
                </li>
                <li>
                  <i class="fas fa-chevron-right"></i><a href="">Register</a>
                </li>
                <li>
                  <i class="fas fa-chevron-right"></i><a href="">SOLUTIONS </a>
                </li>
                <li>
                  <i class="fas fa-chevron-right"></i
                  ><a href="">Submit Ticket</a>
                </li>
                <li>
                  <i class="fas fa-chevron-right"></i
                  ><a href="">Forget Password</a>
                </li>
                <li><i class="fas fa-chevron-right"></i><a href="">blog</a></li>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col xl="3" lg="3" md="6">
          <div class="box-informations">
            <h4>CONTACT INFORMATIO</h4>
            <ul class="list-info">
              <li>
                <h5>Janzur-Tripoli-Libya</h5>
              </li>
              <li><h5>Janzur-Alsharqiya</h5></li>
              <li><h5>Street-15575-Ly</h5></li>
              <li>
                <h5>
                  <i class="fa fa-envelope"></i>info.SaharaCloud@transahara.com
                </h5>
              </li>
              <li>
                <h5><i class="fa fa-phone"></i> 00218911279177</h5>
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div class="links-footer">
      <b-container class="contaner-links">
        <h4>QUICK LINKS</h4>
        <b-row>
          <b-col lg="2" md="3" sm="6">
            <i class="fas fa-chevron-right"></i
            ><a href="">Shared Web Hosting</a></b-col
          >
          <b-col lg="2" md="3" sm="6">
            <i class="fas fa-chevron-right"></i
            ><a href="">Unlimited Reseller Hosting</a></b-col
          >
          <b-col lg="2" md="3" sm="6">
            <i class="fas fa-chevron-right"></i
            ><a href="">Low Cost KVM VPS</a></b-col
          >
          <b-col lg="2" md="3" sm="6">
            <i class="fas fa-chevron-right"></i
            ><a href="">Unlimited Window VPS</a></b-col
          >
          <b-col lg="2" md="3" sm="6">
            <i class="fas fa-chevron-right"></i
            ><a href="">Low Cost Dedicated Server</a></b-col
          >
          <b-col lg="2" md="3" sm="6">
            <i class="fas fa-chevron-right"></i
            ><a href="">Cheap Dedicated Server</a></b-col
          >
        </b-row>
      </b-container>
    </div> -->
    <div class="under-tow-footer">
      <b-container>
        <b-row>
          <b-col lg="8">
            <div class="container-under-footer-link">
              <p>
                Use of this Site is subject to express terms of use. By using
                this site, you signify that you agree to be bound by these
              </p>
            </div>
          </b-col>
          <b-col lg="4">
            <div class="container-under-footer-img">
              <ul class="list-img">
                <li>
                  <a href="">
                    <img src="../assets/cardBussness/sadad.jpg" alt=""
                  /></a>
                </li>
                <li>
                  <a href="">
                    <img src="../assets/cardBussness/atib.jpg" alt=""
                  /></a>
                </li>
                <li>
                  <a href=""
                    ><img src="../assets/cardBussness/tadawul.png" alt=""
                  /></a>
                </li>
                <li>
                  <a href="">
                    <img src="../assets/cardBussness/mastercard1.png" alt=""
                  /></a>
                </li>
                <li>
                  <a href="">
                    <img src="../assets/cardBussness/visa1.png" alt=""
                  /></a>
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="copyright-footer">
      <b-container class="container-copyright">
        <div class="copyright">
          &copy; Copyright <strong><span>Of Transahara</span></strong
          >. All Rights Reserved
        </div>
        <div class="credits">
          Designed by <a href="http://it.transahara.com/">TSIC</a>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
#footer {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 50px;
  background: #0e0b2b;
  .box-links {
    h4 {
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 20px;
      font-family: "Open Sans";
      position: relative;
    }
    h4:after {
      position: absolute;
      bottom: -4px;
      left: 0;
      content: "";
      width: 45px;
      height: 2px;
      background: #7f72e4;
    }
    .list-links {
      ul {
        padding: 0;
        margin: 0;
        li {
          list-style: none;
          color: rgba(255, 255, 255, 0.85);
          transition: 0.2s linear;
          font-family: "Open Sans";
          margin-bottom: 0;
          .fa-chevron-right {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 18px;
            padding-right: 5px;
            color: #ffbe3d;
          }
          a {
            text-decoration: none;
            line-height: 30px;
            font-size: 13px;
            color: rgba(255, 255, 255, 0.85);
            transition: 0.2s linear;
            font-family: "Open Sans";
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .box-informations {
    h4 {
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 20px;
      position: relative;
    }
    h4:after {
      position: absolute;
      bottom: -4px;
      left: 0;
      content: "";
      width: 110px;
      height: 2px;
      background: #7f72e4;
    }
    .list-info {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        h5 {
          color: #aeb8d8;
          font-size: 13px;
          font-family: "Montserrat", sans-serif !important;
          margin-top: 0;
          font-weight: 500;
          margin-bottom: 20px;
          .fa-envelope,
          .fa-phone {
            color: #8adbab;
            padding-right: 3px;
            font-weight: 900;
            font-size: 18px;
          }
        }
      }
    }
  }
  .links-footer {
    .contaner-links {
      padding-top: 14px;
      padding-bottom: 50px;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
    margin-top: 50px;
    b-col {
      line-height: normal;
      margin-right: 30px;
    }
    h4 {
      color: #f7f9ff;
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
      font-family: "Open Sans";
    }
    .fa-chevron-right {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 18px;
      padding-right: 5px;
      color: #ffbe3d;
    }
    a {
      text-decoration: none;
      line-height: 30px;
      font-size: 11px;
      color: #ffffff;
      transition: 0.1s linear;
    }
  }
  .under-tow-footer {
    padding-top: 20px;
    .container-under-footer-link {
      p {
        font-size: 13px;
        padding: 15px 15px;
        text-align: left;
      }
    }
    .container-under-footer-img {
      .list-img {
        display: flex;
        justify-content: flex-end;
        li {
          list-style: none;
          a {
            img {
              margin-left: 10px;
              width: 50px;
              height: 32px;
            }
          }
        }
      }
    }
  }
  .copyright-footer {
    text-align: center;
    padding-top: 30px;
    .container-copyright {
      border-top: 1px solid #936ce2;
      .copyright {
        padding-top: 10px;
        text-align: center;
        font-size: 13px;
        color: #fff;
      }
      .credits {
        padding-top: 10px;
        text-align: center;
        font-size: 13px;
        color: #fff;
        a {
          color: blueviolet;
          text-decoration: none;
        }
      }
    }
  }
}
#footer:after {
  position: absolute;
  top: -300px;
  left: -100px;
  width: 800px;
  height: 800px;
  content: "";
  background: url(../assets/blob-shape.svg);
  background-position: center center;
  background-size: cover;
  z-index: -1;
  opacity: 0.05;
}
</style>
