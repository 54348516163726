<template>
 <div id="whyChooseUs">
  <b-container>
   <div class="text-center title">
    <h2>WHY CHOOSE US</h2>
   </div>
   <b-row cols="1" cols-sm="1" cols-md="2" cols-lg="3">
    <b-col>
     <div class="box-container">
      <i class="fas fa-thumbs-up icons"></i>
      <h4>99.9% Uptime Guarantee</h4>
      <p>
       Your visitors can reach you whenever they want. No embarrassing
       glitches—ever!
      </p>
     </div>
    </b-col>
    <b-col>
     <div class="box-container">
      <i class="fas fa-lock icons"></i>
      <h4>DDOS Protection</h4>
      <p>
       Your website stays available for legitimate visitors with out bulletproof
       protection.
      </p>
     </div>
    </b-col>
    <b-col>
     <div class="box-container">
      <i class="fas fa-check-square icons"></i>
      <h4>24/7 Customer Support</h4>
      <p style="font-size: 14px">
       Don't lose sleep over glitches. We're here for you 24/7 and will respond
       to you within 60 minutes.
      </p>
     </div>
    </b-col>
   </b-row>
  </b-container>
 </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@700&display=swap");
#whyChooseUs {
 padding: 70px 0 70px 0;
 background-color: #1c2746;
 display: flex;
 .title {
  margin: 28px 0;
  h2 {
   font-size: 36px;
   font-family: "Overpass", sans-serif;
   color: blanchedalmond;
  }
 }
 .box-container {
  text-align: center;
  padding: 0 40px 50px;
  border-radius: 15px;
  background: transparent;
  border: 2px solid blanchedalmond;
  box-shadow: 10px 10px 0px #ccc;
  margin: 15px 0;
  .icons {
   font-size: 65px;
   margin: 50px 0 30px;
   color: blanchedalmond;
  }
  h4 {
   color: blanchedalmond;
   font-family: "Overpass", sans-serif;
   font-size: 23px;
   font-weight: 600;
   margin-bottom: 15px;
  }
  p {
   color: blanchedalmond;
   font-size: 16px;
   line-height: 25px;
   font-family: "Montserrat", sans-serif !important;
  }
 }
}
</style>
