import { initialState } from "./state";
export const mutations = {
  // auth_request(state, { token, user }) {
  //   state.token = token;
  //   state.user = user;
  // },

  assignToken(data) {
    initialState.token = data.token;
    initialState.user = data.user;
  },

  logout() {
    initialState.token = "";
    initialState.user = {};
  },

  /*   auth_request(state) {
    state.status = "loading";
  },
  auth_success(state, token, user) {
    state.status = "success";
    state.token = token;
    state.user = user;
  },
  auth_error(state) {
    state.status = "error";
  },
  logout(state) {
    state.status = "";
    state.token = "";
  }, */
};
