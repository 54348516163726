import { cartRoutes } from "./router/cart-routes";
import { cartStore } from "./store";

export class CartModule {
 constructor(router, store) {
  this.router = router;
  this.store = store;
  this.name = "cart";
 }

 install(/*Vue*/) {
  // this.router.addRoutes(cartRoutes);
  for (let cartRoute of cartRoutes) {
   this.router.addRoute(cartRoute);
  }
  this.store.registerModule([this.name], cartStore);
 }
}
