import Home from "../views/Home.vue";
import AboutUs from "../views/AboutUs.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import TermsOfService from "../views/TermsOfService.vue";
import Contacts from "../views/Contacts.vue";
import webdeveloper from "../views/WebDeveloper.vue";
const FAQ = () => import(/* webpackChunkName: "FAQ" */ "../views/FAQ.vue");
const PageNotFound = () => import(/* webpackChunkName: "PageNotFound" */ "../views/PageNotFound.vue");
/* import { auth } from "@/middleware";
import { log } from "@/middleware"; */
export const homeRoutes = [
 {
  path: "/",
  name: "home",
  component: Home,
 },
 {
  path: "/aboutus",
  name: "aboutus",
  component: AboutUs,
  /*   meta: {
   middleware: [auth, log],
  }, */
 },
 {
  path: "/privacy-policy",
  name: "privacyPolicy",
  component: PrivacyPolicy,
 },
 {
  path: "/terms-of-service",
  name: "TermsOfService",
  component: TermsOfService,
 },
 {
  path: "/contacts",
  name: "Contacts",
  component: Contacts,
 },
 {
  path: "/web-developer",
  name: "webdeveloper",
  component: webdeveloper,
 },
 {
  path: "/FAQ",
  name: "FAQ",
  component: FAQ,
 },
  { path: "*", component: PageNotFound }, 
];
