<template>
 <div id="webDeveloper">
  <Navigation/>
  <div class="headerWebDeveloper">
   <b-container>
    <b-row>
     <b-col xl="6" md="12">
      <div class="container-title">
       <h2>Web Development</h2>
       <p>
        we have dedicated team of professionals developers and designers, making
        powerful websites.
       </p>
      </div>
     </b-col>
     <b-col xl="6" md="12">
      <img
       src="../assets/web Developer/webDeveloper.png"
       alt="headerWebDeveloper"
      />
     </b-col>
    </b-row>
   </b-container>
  </div>
  <div id="whyWebDeveloper">
   <b-container>
    <b-row>
     <div class="text-center title">
      <h2>WHY 1STVAULT WEB DEVELOPER</h2>
     </div>
     <b-col lg="4" md="6" sm="1">
      <div class="box-container">
       <i class="icon fa fa-paint-brush"></i>
       <h4>Attractive Website Design</h4>
       <p>
        We design attractive websites based on W3C standard that are most
        compatible with all devices.
       </p>
      </div>
     </b-col>
     <b-col lg="4" md="6" sm="1">
      <div class="box-container">
       <i class="icon fa fa-cogs"></i>
       <h4>Custom Web Development</h4>
       <p>
        1STVAULT offers Up-to-date design, development services and customized
        website according to you.
       </p>
      </div>
     </b-col>
     <b-col lg="4" md="6" sm="1">
      <div class="box-container">
       <i class="icon fa fa-user"></i>
       <h4>Easy to Use</h4>
       <p>
        We develop website that are very easy to use and understand. Non-IT
        users can use very easily.
       </p>
      </div>
     </b-col>
    </b-row>
   </b-container>
  </div>
  <div id="skillTeemWebDeveloper">
   <b-container>
    <b-row>
     <b-col lg="6" md="12">
      <div class="cover-img">
       <img src="../assets/web Developer/SkillTeem.png" alt="" />
      </div>
     </b-col>
     <b-col lg="6" md="12">
      <div class="details-skill">
       <div class="breaf">
        <h2>Technologies We use</h2>
        <p>
         We have a dedicated team of experts to design and develop various
         creative applications. They often create applicationns by using some of
         the following Technologies.
        </p>
       </div>
      </div>
      <div class="skills">
       <ul>
        <li>
         <i class="fab fa-dev"></i>
         <span>Multi-page dynamic websites</span>
        </li>
        <li>
         <i class="fab fa-dev"></i>
         <span>ٍStatic and multi-page websites</span>
        </li>
        <li>
         <i class="fab fa-dev"></i>
         <span>Multilingual website</span>
        </li>
        <li>
         <i class="fab fa-dev"></i>
         <span>Site control panel</span>
        </li>
        <li>
         <i class="fab fa-dev"></i>
         <span>We use advanced and reliable technology</span>
        </li>
        <li>
         <i class="fab fa-dev"></i>
         <span>Responsive website on all devices</span>
        </li>
       </ul>
      </div>
      <div class="iconsSkills">
       <b-row>
        <b-col>
         <div class="icon">
          <img src="../assets/skill/HTML5.png" alt="" />
         </div>
        </b-col>
        <b-col>
         <div class="icon">
          <img src="../assets/skill/CSS3.png" alt="" />
         </div>
        </b-col>
        <b-col>
         <div class="icon">
          <img src="../assets/skill/PHP.png" alt="" />
         </div>
        </b-col>
        <b-col>
         <div class="icon">
          <img src="../assets/skill/LARAVEL8.png" alt="" />
         </div>
        </b-col>
        <b-col>
         <div class="icon">
          <img src="../assets/skill/NODEJS.png" alt="" />
         </div>
        </b-col>
        <b-col>
         <div class="icon">
          <img src="../assets/skill/VUEJS3.png" alt="" />
         </div>
        </b-col>
        <b-col>
         <div class="icon">
          <img src="../assets/skill/EMBERJS.png" alt="" />
         </div>
        </b-col>
       </b-row>
      </div>
     </b-col>
    </b-row>
   </b-container>
  </div>
  <Partners />
  <Footer />
 </div>
</template>

<script>


import Partners from "../components/Partners.vue";
import Footer from "../components/Footer.vue";
import Navigation from "../components/Navigation.vue";
export default { components: { Partners, Footer, Navigation } };
</script>

<style lang="scss" scoped>
#webDeveloper {
 .headerWebDeveloper {
  position: relative;
  background-color: #0e0b2b;
  background-size: cover;
  padding: 75px 0;
  .container-title {
   padding: 65px 0 0 0;
   h2 {
    font-weight: 800;
    font-size: 40px;
    margin-top: 0;
    font-family: "Overpass", sans-serif;
    text-transform: uppercase;
   }
   p {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 30px;
    color: #ffffff;
    transition: 0.2s linear;
    font-family: "Montserrat", sans-serif !important;
   }
  }
  img {
   width: 100%;
  }
 }
 .headerWebDeveloper::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-color: #ffffff;
  clip-path: polygon(100% 69%, 0 100%, 100% 104%);
 }
 #whyWebDeveloper {
  padding: 70px 0 70px 0;
  background-color: #fff;
  display: flex;
  .title {
   margin: 28px 0;
   h2 {
    font-size: 36px;
    font-family: "Overpass", sans-serif;
    color: #1c2746;
   }
  }
  .box-container {
   width: 350px;
   text-align: center;
   padding: 0 26px 20px;
   border-radius: 15px;
   margin: 0 15px;
   background: transparent;
   border: 2px solid #ccc;
   box-shadow: 10px 10px 0px #ccc;
   .icon {
    font-size: 65px;
    margin: 50px 0 30px;
    color: #1c2746;
   }
   h4 {
    color: #1c2746;
    font-family: "Overpass", sans-serif;
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 15px;
   }
   p {
    color: #1c2746;
    font-size: 16px;
    line-height: 25px;
    font-family: "Montserrat", sans-serif !important;
    a {
     text-decoration: none;
     color: #1c2746;
     font-size: 16px;
    }
   }
  }
 }
 #skillTeemWebDeveloper {
  padding: 70px 0 70px 0;
  background-color: #0e0b2b;
  .cover-img {
   img {
    width: 100%;
   }
  }
  .details-skill {
   .breaf {
    margin-bottom: 35px;
    h2 {
     color: #ecf0fc;
     font-family: "Overpass", sans-serif;
     font-size: 26px;
     font-weight: 600;
     margin-bottom: 15px;
     text-transform: uppercase;
    }
    p {
     color: #ecf0fc;
     font-size: 18px;
     line-height: 25px;
     font-family: "Montserrat", sans-serif !important;
    }
   }
  }
  .skills {
   ul {
    margin-bottom: 40px;
    li {
     margin-bottom: 7px;
     list-style: none;
     .fa-dev {
      color: #0dcaf0cc;
      width: 25px;
      height: 25px;
     }
     span {
      font-size: 16px;
      color: #85bceb;
      transition: 0.2s linear;
      font-family: "Open Sans";
      margin-bottom: 0;
      padding-left: 7px;
      letter-spacing: 1px;
     }
    }
   }
  }
  .iconsSkills {
   margin-top: 25px;
   .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 67px;
    height: 67px;
    background: #8d3de224;
    width: 100%;
    border-radius: 3px;
    box-shadow: 0 0 0px 8px #0dcaf063;
    img {
     width: 80%;
    }
    /*   img::hover {
    } */
   }
  }
 }
}
</style>
