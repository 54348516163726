import middleware from "@/middleware/";

export class MiddlewareModule {
  static name = "middleware";
  /**
   * create core
   * @param {import('vue-router').Route} router
   */
  constructor(router, store) {
    this.router = router;
    this.store = store;
  }
  install() {
    this.router.beforeEach((to, from, next) => {
      middleware(to, from, next, this.router, this.store);
    });
  }
}
