<template>
 <div id="boxesStyleOne">
  <b-container>
   <h2>1stvault Cloud Services</h2>
   <b-row cols-xl="3" cols-lg="3" cols-md="2" cols-sm="1" cols="1">
    <b-col>
     <div class="container-box">
      <div class="container-img">
       <img src="../assets/cloudstoragetoww.png" alt="img-boxes11" />
      </div>
      <i class="fas fa-dice-d20"></i>
      <h4>Cloud Storage</h4>
      <div class="container-list">
       <ul>
        <li>
         <span>Solid State Drive (SSD)</span>
        </li>
        <li>
         <span>64-bit Windows OS or Linux</span>
        </li>
        <li>
         <span> 32GB RAM</span>
        </li>
        <li>
         <span>Quad-core Intel® CPU</span>
        </li>
       </ul>
      </div>
      <!-- <div class="container-btn">
       <button>Read More <i class="fas fa-long-arrow-alt-right"></i></button>
      </div> -->
     </div>
    </b-col>
    <b-col>
     <div class="container-box">
      <div class="container-img">
       <img src="../assets/colocation.png" alt="img-boxes11" />
      </div>
      <i class="fas fa-dice-d20"></i>
      <h4>Colocation</h4>
      <div class="container-list">
       <ul>
        <li>
         <span>15 cPanel Accounts</span>
        </li>
        <li>
         <span>50GB SSD Disk Space</span>
        </li>
        <li>
         <span>Unlimited Bandwidth</span>
        </li>
        <li>
         <span>WHM Control Panel</span>
        </li>
        <li>
         <span>Choice of Locations</span>
        </li>
        <li>
         <span>Instant Setup</span>
        </li>
        <li>
         <span>Custom Nameservers</span>
        </li>
       </ul>
      </div>
      <!-- <div class="container-btn">
       <button>Read More <i class="fas fa-long-arrow-alt-right"></i></button>
      </div> -->
     </div>
    </b-col>
    <b-col>
     <div class="container-box">
      <div class="container-img">
       <img src="../assets/domains.png" alt="img-boxes11" />
      </div>
      <i class="fas fa-dice-d20"></i>
      <h4>Domains</h4>
      <div class="container-list">
       <ul>
        <li>
         <span>Domain Management Panel</span>
        </li>
        <li>
         <span>DNS Management</span>
        </li>
        <li>
         <span>Domain Forwarding </span>
        </li>
        <li>
         <span>Domain Locking</span>
        </li>
        <li>
         <span>Subdomains Management</span>
        </li>
       </ul>
      </div>
      <!-- <div class="container-btn">
       <button>Read More <i class="fas fa-long-arrow-alt-right"></i></button>
      </div> -->
     </div>
    </b-col>
   </b-row>
  </b-container>
 </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@700&display=swap");
#boxesStyleOne {
 padding: 70px 0 70px 0;
 background-color: #1c2746;
 h2 {
  text-align: center;
  font-family: "Overpass", sans-serif;
  font-size: 36px;
  font-style: inherit;
  text-transform: uppercase;
  margin-bottom: 37px;
 }
 .container-box {
  padding: 35px 10px 35px 10px;
  background-color: #19233f;
  border-radius: 2px 2px 10px 10px;
  text-align: center;
  margin: 10px 0;
  background: #19233f;
  border-top: 5px solid #ecf0f0;
  box-shadow: 0px 0px 20px #040917;
  .container-img {
   margin-top: 10px;
   margin-bottom: 53px;
   border-radius: 2px 2px 10px 10px;
   box-shadow: 0px 7px 6px #918f8f;
  
   img {
    width: 100%;
    height: 100%;
   }
  }
  .fa-dice-d20 {
   color: #f1be5e;
   font-size: 32px;
  }
  h4 {
   font-size: 28px;
   text-transform: capitalize;
   margin: 11px 0 20px 0;
   font-family: "Montserrat", sans-serif !important;
   color: blanchedalmond;
  }
  .container-list {
   ul {
    margin: 0 0 30px 0;
    padding: 0;
    li {
     list-style: none;
     padding: 3px 0 3px 0;
     margin-bottom: 0;

     span {
      font-size: 20px;
      text-align: center;
      color: #718096;
      transition: 0.2s linear;
      font-family: "Open Sans";
     }
    }
   }
  }
  .container-btn {
   button {
    line-height: 16px;
    padding: 12px 35px 12px;
    border: 2px solid #8d3de2;
    border-radius: 5px;
    background-color: #8d3de2;
    color: blanchedalmond;
    text-align: center;
    font-family: "Montserrat", sans-serif !important;
   }
   button:hover {
    background-color: #5802b5;
    border: 2px solid #5802b5;
   }
  }
 }
}
</style>
