<template>
 <div id="Navigation">
  <header :class="{ 'scrolled-nav': scrolledNav }">
   <nav>
    <div class="branding">
     <img src="../assets/logo-grediant.png" alt="" />
    </div>
    <ul style="padding-start: 3em" v-show="!mobile" class="navigation">
     <li>
      <router-link class="link" to="/">Home</router-link>
     </li>
     <!-- <li @click="aboutMenue" class="tapAbout">
      <router-link class="link" :to="{ name: '' }"
       >About Us <i class="fas fa-sort-down fa-lg"></i
      ></router-link>
      <div v-show="aboutusMenue" class="menue-about">
       <ul>
        <li><router-link to="/aboutus" class="link">about us</router-link></li>
        <li>
         <router-link to="/privacy-policy" class="link"
          >Privacy Policy</router-link
         >
        </li>
        <li>
         <router-link to="/terms-of-service" class="link"
          >Terms of Servicey</router-link
         >
        </li>
       </ul>
      </div>
     </li>
     <li @click="MegaMenueCategorieas" id="categries">
      <router-link class="link" :to="{ name: '' }"
       >Products <i class="fas fa-sort-down fa-lg"></i
      ></router-link>
      <div v-show="megaMenue" class="categriess">
       <b-container>
        <b-row>
         <b-col class="coling">
          <div class="container-categries">
           <div class="name-categories">
            <h4><i class="fa fa-server"></i>Web Hosting</h4>
           </div>
           <div class="section-categories">
            <ul>
             <li>
              <router-link to="shared-hosting" class="link"
               >Shared Web Hosting.</router-link
              >
             </li>
             <li>
              <router-link to="shared-hosting" class="link"
               >Virtual Privet Server (VPS).</router-link
              >
             </li>
             <li>
              <router-link to="shared-hosting" class="link"></router-link
              >Dedicated Servers
             </li>
            </ul>
           </div>
          </div>
         </b-col>
         <b-col class="coling">
          <div class="container-categries">
           <div class="name-categories">
            <h4><i class="fa fa-server"></i>Cloud Services</h4>
           </div>
           <div class="section-categories">
            <ul>
             <li>
              <router-link to="shared-hosting" class="link"
               >Elastic Cloud Service (ECS).</router-link
              >
             </li>
             <li>
              <router-link to="shared-hosting" class="link"
               >Cloud Storage</router-link
              >
             </li>
             <li>
              <router-link to="shared-hosting" class="link"
               >Managed Services Support</router-link
              >
             </li>
            </ul>
           </div>
          </div>
         </b-col>
         <b-col class="coling">
          <div class="container-categries">
           <div class="name-categories">
            <h4><i class="fa fa-server"></i>Domains</h4>
           </div>
           <div class="section-categories">
            <ul>
             <li>
              <router-link to="shared-hosting" class="link"
               >Register a new domain</router-link
              >
             </li>
             <li>
              <router-link to="shared-hosting" class="link"
               >Transfer domain from another registrar</router-link
              >
             </li>
             <li>
              <router-link to="shared-hosting" class="link">
               existing domain and update my nameservers</router-link
              >
             </li>
            </ul>
           </div>
          </div>
         </b-col>
         <b-col class="coling">
          <div class="container-categries">
           <div class="name-categories">
            <h4><i class="fa fa-server"></i> Security</h4>
           </div>
           <div class="section-categories">
            <ul>
             <li>
              <router-link to="shared-hosting" class="link">SSL</router-link>
             </li>
            </ul>
           </div>
          </div>
         </b-col>
        </b-row>
       </b-container>
      </div>
     </li>
     <li>
      <router-link class="link" to="/web-developer">Web Developer</router-link>
     </li>
     <li><router-link class="link" to="/FAQ">FAQ</router-link></li>
     <li><router-link class="link" to="/contacts">Contact</router-link></li>
     <li class="language">
      <a class="link" title="language">
       <span v-on:click="changeLocale" class="languagelist">
        <i class="fas fa-globe-asia"></i>{{ $t("navigation.lang") }}</span
       >
      </a>
     </li>
     <li class="shooping">
      <router-link class="link" to="/ShoppingCartEmpty">
       <i class="fas fa-shopping-cart"></i> <span class="cartitemcount">0</span>
      </router-link>
     </li> -->
     
     <li @click="authMenue" class="tapLogin" style="display: flex; align-items: center; flex: 1; justify-content: flex-end !important;">
      <router-link class="link" :to="{ name: '' }">
       <button class="btn-auth-submit" type="button">
        <i class="fa fa-lock"></i> Login
        <i class="fa fa-angle-down"></i></button
      ></router-link>
      <div v-show="menueAuth" class="dropdownMenueLogin">
       <section>
        <div class="title">
         <h4><i class="fa fa-users"></i> Registered Users</h4>
        </div>
        <div class="haveAccount">
         <p>Have an Account ?</p>
         <span
          ><router-link class="link" to="/login"> Login |</router-link></span
         >
         <span
          ><router-link class="link" to="/newpassword">
           Forgot Password?
          </router-link></span
         >
        </div>
        <hr />
        <div class="newUser">
         <h4><i class="fa fa-user"></i> New User</h4>
         <p>New to 1sVaultCloud ?</p>
         <p>Create an Account to get started now.</p>
         <router-link class="link" to="/regster"
          ><h4>Create an Account</h4></router-link
         >
        </div>
       </section>
      </div>
     </li>
     <!-- Please replace this button with the . button  login with logedin-->
     <li hidden>
      <button class="nav-link" to="" @click="logout">Logout</button>
     </li>
    </ul>

    <div class="icon">
     <i
      @click="toogleMobileNav"
      v-show="mobile"
      class="fas fa-bars"
      :class="{ 'icon-active': mobileNav }"
     >
     </i>
    </div>
    <transition name="mobile-nav">
     <ul v-show="mobileNav" class="dropdown-nav">
      <li>
       <router-link class="link" :to="{ name: 'Home' }">Home</router-link>
      </li>
      <li><router-link class="link" :to="{ name: '' }">products</router-link></li>
      <li>
       <router-link class="link" :to="{ name: '' }">web-developer</router-link>
      </li>
      <li>
       <router-link class="link" :to="{ name: '' }">Contact</router-link>
      </li>
     </ul>
    </transition>
   </nav>
  </header>
 </div>
</template>

<script>
import { authenticationStore } from "../../authentication/store/index";
export default {
 name: "navigation",
 data() {
  return {
   scrolledNav: null,
   mobile: null,
   mobileNav: null,
   windowWidth: null,
   menueAuth: false,
   aboutusMenue: false,
   megaMenue: false,
  };
 },
 created() {
  window.addEventListener("resize", this.checkScreen);
  this.checkScreen();
 },
 mounted() {
  window.addEventListener("scroll", this.updateScroll);
 },
 methods: {
  logout() {
   authenticationStore.actions.logout(authenticationStore);
  },
  changeLocale() {
   const locale = this.$i18n.locale;
   this.$i18n.locale = locale == "en" ? "ar" : "en";
   if (locale == "en") {
    document.body.dir = "rtl";
    document.body.dir = "rtl";
    document.body.setAttribute("lang", "ar");
   } else {
    document.body.dir = "ltr";
    document.body.setAttribute("lang", "en");
   }
  },
  toogleMobileNav() {
   this.mobileNav = !this.mobileNav;
  },
  updateScroll() {
   const scrollPostion = window.scrollY;
   if (scrollPostion > 50) {
    this.scrolledNav = true;
    return;
   }
   this.scrolledNav = false;
  },
  checkScreen() {
   this.windowWidth = window.innerWidth;
   if (this.windowWidth <= 750) {
    this.mobile = true;
    return;
   }
   this.mobile = false;
   this.mobileNav = false;
   return;
  },
  authMenue() {
   this.menueAuth = !this.menueAuth;
  },
  aboutMenue() {
   this.aboutusMenue = !this.aboutusMenue;
  },
  MegaMenueCategorieas() {
   this.megaMenue = !this.megaMenue;
  },
 },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors/_main-colors.scss";
@import "@/styles/fonts/_main-fonts.scss";
#Navigation {
 background-color: rgb(14 ,11 ,43);
 z-index: 99;
 width: 100%;
 position: fixed;
 transition: 0.5s ease all;
 color: #fff;
 header {
  nav {
   position: relative;
   display: flex;
   flex-direction: row;
   padding: 12px 0;
   transition: 0.5s ease all;
   width: 90%;
   margin: 0 auto;
   @media (min-width: 1140px) {
    max-width: 1140px;
   }
   ul {
    padding: 0;
    margin: 0;
   }
   ul,
   .link {
    font-family: "Montserrat", sans-serif !important;
    font-size: 13px;
    text-decoration: none;
    color: blanchedalmond;

    .fa-sort-down {
     color: #8d3de2;
     margin: 0 0 3px 2px;
     cursor: pointer;
    }
   }

   li {
    list-style: none;
    padding: 10px;
    margin-left: 10px;
   }
   #categries {
    position: relative;

    .link {
    }
    .categriess {
     position: absolute;
     left: 0;
     top: 100%;
     width: 1033px;
     z-index: 99;
     border: 0 solid #e0e0e0;
     border-radius: 10px;
     background: #263152;
     opacity: 1 !important;
     list-style: none;
     right: auto;
     left: 45%;
     transform: translate(-50%, 0);
     border-radius: 5px;
     margin-top: 10px;
     padding: 20px 10px;
     .coling {
      border-right: solid 1px rgba(240, 240, 240, 0.1);
      .container-categries {
       padding: 0 5px 0 0;

       .name-categories {
        border-bottom: solid 1px rgba(240, 240, 240, 0.1);
        h4 {
         font-size: 14px;
         color: #dee5f7;
         padding-bottom: 10px;
         text-transform: uppercase;
         font-weight: 700;
         font-family: $Headings-font;

         .fa-server {
          color: #456bca;
          font-size: 16px;
          margin-right: 4px;
         }
        }
       }
       .section-categories {
        margin-top: 10px;
        ul {
         li {
          .link {
           color: #dee5f7;
           transition: 0.2s linear;
           font-family: $Paragraph-font;
           font-size: 14px;
           margin-bottom: 0;
           font-weight: 500;
           border: none;
           &:hover {
            border: none;
           }
          }

          &:hover {
           background-color: #8d3de2;
           cursor: pointer;
          }
         }
        }
       }
      }
     }
     .coling:last-child {
      border-right: none;
     }
    }
    .categriess::after {
     content: " ";
     position: absolute;
     left: 49%;
     top: -15px;
     border-top: none;
     border-right: 15px solid transparent;
     border-left: 15px solid transparent;
     border-bottom: 15px solid #263152;
    }
   }
   .shooping {
    position: relative;
    .link {
     .fa-shopping-cart {
      font-size: 21px;
     }

     .cartitemcount {
      background: #8d3de2;
      padding: 0px 5px;
      border-radius: 50px;
      position: absolute;
      left: 24px;
      top: -4px;
      text-align: center;
     }
    }
   }
   .language {
    .link {
     border-bottom: 1px solid transparent;
     span {
      cursor: pointer;
      font-size: 15px;
      justify-content: flex-end;
      justify-content: space-around;
      color: blanchedalmond;
      font-family: "Montserrat", sans-serif !important;
      .fa-globe-asia {
       font-size: 15px;
       margin: 0;
       margin-right: 3px;
       margin-left: 3px;
      }
      &:hover {
       color: #00afaa;
      }
     }
    }
    a {
     .languagelist {
      font-family: $lang-arabic-paragraph;
     }
    }
   }
   .tapAbout {
    position: relative;
   }
   .menue-about {
    position: absolute;
    left: 0;
    top: 100%;
    width: 233px;
    z-index: 2;
    margin-top: -0.002rem;
    background-color: #263152;
    opacity: 1 !important;
    list-style: none;
    right: auto;
    left: 45%;
    transform: translate(-50%, 0);
    border-radius: 5px;
    margin-top: 10px;
    ul {
     li {
      margin: 0;
      padding: 13px 14px;
      .link {
       text-align: left;
       color: #fff;
       padding: 0 0 3px 14px;
       text-decoration: none;
       border: none;
      }
      .link:hover {
       color: #fff;
       font-family: $Paragraph-font;
      }
     }
     li:hover {
      background-color: #8d3de2;
      transition: 0.4s;
     }
    }
   }
   .menue-about::after {
    content: " ";
    position: absolute;
    left: 45%;
    top: -15px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid #263152;
   }
   .tapLogin {
    position: relative;
   }
   button {
    border: 2px solid #8d3de2;
    border-radius: 6px;
    background-color: #8d3de2;
    justify-content: flex-end;
    justify-content: space-around;
    color: blanchedalmond;
    font-family: "Montserrat", sans-serif !important;
    padding: 9px 20px;
    font-weight: 700;
    .fa-lock {
     margin-right: 3px;
    }
    .fa-angle-down {
     margin-left: 3px;
    }
   }
   .dropdownMenueLogin {
    position: absolute;
    left: 0;
    top: 100%;
    width: 320px;
    z-index: 2;
    margin-top: -0.002rem;
    background-color: #fff;
    opacity: 1 !important;
    max-width: 1200px;
    list-style: none;
    right: auto;
    left: 90%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    border-radius: 5px;
    padding: 25px 25px;
    margin-top: 10px;
    section {
     .title {
      h4 {
       font-size: 16px;
       font-weight: 600;
       font-family: "Roboto", sans-serif;
       line-height: 1.3rem;
       color: #464646;
       .fa-users {
        font-size: 16px;
       }
      }
     }
     .haveAccount {
      p {
       padding: 0;
       margin: 0;
       color: #8392a5;
       font-size: 14px;
       margin-top: 18px;
      }
      span {
       .link {
        color: #00afaa;
        border: none;
        font-weight: 500;
       }
      }
     }
     hr {
      color: #464646;
     }
     .newUser {
      h4 {
       font-size: 16px;
       font-weight: 600;
       font-family: "Roboto", sans-serif;
       line-height: 1.3rem;
       color: #464646;
       .fa-user {
        font-size: 16px;
       }
      }
      p {
       padding: 0;
       margin: 0;
       color: #8392a5;
       font-size: 14px;
       margin-top: 18px;
      }
      .link {
       h4 {
        color: #00afaa;
        border: none;
        font-weight: 500;
       }
      }
     }
    }
   }
   .dropdownMenueLogin::after {
    content: " ";
    position: absolute;
    left: 50%;
    top: -15px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid #fff;
   }
   .link {
    font-size: 14px;
    transition: 0.5 ease all;
    padding-bottom: 4px;
    border-bottom: 1px solid transparent;
    &:hover {
     color: #00afaa;
     border-color: #00afea;
    }
   }
   .shooping .link:hover {
    color: blanchedalmond;
    border: none;
   }
   .branding {
    display: flex;
    align-items: center;
    img {
     width: 160px;
     transition: 0.5s ease all;
    }
   }
   .navigation {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-start;
   }
   .icon {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 24px;
    height: 100%;
    .fa-bars {
     cursor: pointer;
     font-size: 14px;
     transition: 0.8s ease all;
    }
   }
   .icon-active {
    transform: rotate(180deg);
   }
   .dropdown-nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    max-width: 250px;
    height: 100%;
    background-color: #fff;
    top: 0;
    left: 0;
    li {
     margin-left: 0;
     .link {
      color: rgba(14, 11, 43, 1);
     }
    }
   }
   .mobile-nav-enter-active,
   .mobile-nav-leave-active {
    transition: 1s ease all;
   }
   .mobil-nav-enter-from,
   .mobil-nav-leave-to {
    transform: translateX(-250px);
   }
   .mobil-nav-enter-to {
    transform: translateX(0);
   }
  }
 }
 .scrolled-nav {
  background-color: rgba(14, 11, 43, 1);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
   0 2px 4px -1px rgba(0, 0, 0, 0.06);
  nav {
   padding: 8px 0;
   .branding {
    img {
     width: 100px;
     box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
   }
  }
 }
}
:lang(en) {
 #Navigation {
 }
}
</style>
