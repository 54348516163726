<template>
  <div id="Contacts">
<Navigation/>
    <div class="headerContacts">
      <b-container>
        <b-row>
          <b-col class="container-title" lg="6" md="12">
            <p>Contact us.</p>
            <h2>GET IN TOUCH</h2>
          </b-col>
          <b-col class="container-img" lg="6" md="12"
            ><img src="../assets/contact.png" alt="about"
          /></b-col>
        </b-row>
      </b-container>
    </div>
    <div id="whyChooseUs">
      <b-container>
        <b-row>
          <div class="text-center title">
            <h2>CONTACT WITH US</h2>
          </div>
          <b-col lg="4" md="6" sm="1">
            <div class="box-container">
              <i class="icon fa fa-phone-square"></i>
              <h4>Make a Call</h4>
              <p>+218911279177</p>
            </div>
          </b-col>
          <b-col lg="4" md="6" sm="1">
            <div class="box-container">
              <i class="icon fa fa-home"></i>
              <h4>Find us</h4>
              <p>
                Handicapped Bridge / Eastern Janzour, the capital, Tripoli,
                State of Libya
              </p>
            </div>
          </b-col>
          <b-col lg="4" md="6" sm="1">
            <div class="box-container">
              <i class="icon fa fa-envelope"></i>
              <h4>Send Mail</h4>
              <p>
                <a href="mailto:dc_unit@transahara.com"
                  >dc_unit@transahara.com</a
                >
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="contact-forms">
      <b-container>
        <div class="main-title">
          <p>Have question?</p>
          <h2>SEND A MESSAGE</h2>
        </div>
        <b-row>
          <b-col xl="7" lg="7" md="12">
            <b-form class="container-form">
              <b-form-group>
                <b-form-input
                  type="text"
                  placeholder="Name *"
                  class="input-contacts"
                ></b-form-input>
                <b-form-input
                  type="email"
                  placeholder="Email *"
                  class="input-contacts"
                ></b-form-input>
                <b-form-input
                  type="text"
                  placeholder="Subject *"
                  class="input-contacts"
                ></b-form-input>
                <b-form-textarea
                  class="input-contacts"
                  placeholder="Message *"
                ></b-form-textarea>
              </b-form-group>
              <button type="submit">
                <span><i class="fa fa-envelope"></i></span> Send your Message
              </button>
            </b-form>
          </b-col>
          <b-col xl="5" lg="5" md="12">
            <img src="../assets/contact-page-banner.png" alt="" />
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="container-map-gps">
      <b-container>
        <b-row>
          <b-col>
            <div class="mapSaharaCloudCompany">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d26821.436310050125!2d13.04663!3d32.827255!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x70fe1ce400b80cec!2z2LnYp9io2LHYqSDYp9mE2LXYrdix2KfYoSDZiCDYp9mE2YbYrtmE2KrZitmG!5e0!3m2!1sen!2sus!4v1639267319044!5m2!1sen!2sus"
                width="100%"
                height="350"
                frameborder="0"
                style="border: 0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <Partners />
    <Footer />
  </div>
</template>

<script>

import Partners from "../components/Partners.vue";
import Footer from "../components/Footer.vue";
import Navigation from "../components/Navigation.vue";
export default {
  name: "Contacts",
  components: { Partners, Footer, Navigation },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@700&display=swap");
#Contacts {
  .headerContacts {
    position: relative;
    padding-top: 70px;
    padding-bottom: 80px;
    background-color: #0e0b2b;
    .container-title {
      padding-top: 81px;
      h2 {
        padding: 0 0 30px 0;
        font-weight: 800;
        font-size: 40px;
        color: #008aff;
        font-family: "Montserrat", sans-serif !important;
        letter-spacing: 1px;
      }
      p {
        margin-top: 54px;
        font-size: 17px;
        line-height: 26px;
        font-family: "Montserrat", sans-serif !important;
        transition: 0.2s linear;
        color: blanchedalmond;
      }
    }
    .container-img {
      padding-left: 25px;
      img {
        width: 95%;
      }
    }
  }
  #whyChooseUs {
    padding: 70px 0 70px 0;
    background-color: #fff;
    display: flex;
    .title {
      margin: 28px 0;
      h2 {
        font-size: 36px;
        font-family: "Overpass", sans-serif;
        color: #1c2746;
      }
    }
    .box-container {
      width: 350px;
      height: 286.19px;
      text-align: center;
      padding: 0 40px 50px;
      border-radius: 15px;
      margin: 0 15px;
      background: transparent;
      border: 2px solid #ccc;
      box-shadow: 10px 10px 0px #ccc;
      .icon {
        font-size: 65px;
        margin: 50px 0 30px;
        color: #1c2746;
      }
      h4 {
        color: #1c2746;
        font-family: "Overpass", sans-serif;
        font-size: 23px;
        font-weight: 600;
        margin-bottom: 15px;
      }
      p {
        color: #1c2746;
        font-size: 16px;
        line-height: 25px;
        font-family: "Montserrat", sans-serif !important;
        a {
          text-decoration: none;
          color: #1c2746;
          font-size: 16px;
        }
      }
    }
  }
   .contact-forms {
     padding-top: 80px;
     padding-bottom: 80px;
     background-color: #0e0b2b;
     .main-title {
       padding-bottom: 60px;
       p {
         font-size: 18px;
         color: rgba(255, 255, 255, 0.85);
         transition: 0.2s linear;
         font-family: "Montserrat", sans-serif !important;
         margin-bottom: 0;
         text-align: center;
       }
       h2 {
         font-size: 36px;
         font-weight: 700;
         font-family: "Overpass", sans-serif;
         color: #ecf0fc;
         text-align: center;
       }
     }
     .container-form {
       .input-contacts {
         color: blanchedalmond;
         width: 100%;
         font-size: 13px;
         background-color: #1c2746;
         border-radius: 5px;
         box-shadow: inset 0 0 0;
         height: 47px;
         transition: 0.3s linear;
         margin: 10px 0;
         box-shadow: inset 0 0 0 #fff;
         border: 0;
         border-color: #fff;
       }
       button {
         box-shadow: inset 0 0 0 #fff;
         margin: 15px 0;
         background-color: #8d3de2;
         color: blanchedalmond;
         font-size: 16px;
         padding: 14px 42px 14px;
         transition: 0.1s linear;
         border-radius: 3px;
         border: none;
         font-family: "Montserrat", sans-serif !important;
         span {
           margin-right: 3px;
           .fa-envelope {
             font-size: 16px;
             text-align: center;
             color: blanchedalmond;
           }
         }
       }
       button:hover {
         background-color: #5303a9;
       }
     }
     img {
       width: 100%;
     }
   }
   .container-map-gps {
     position: relative;
     padding-top: 80px;
     padding-bottom: 80px;
     background-color: #ffff;
     .mapSaharaCloudCompany {
       iframe {
         border-radius: 15px;
       }
     }
   }
   .container-map-gps::before {
     content: "";
     position: absolute;
     bottom: -1px;
     left: 0;
     width: 100%;
     height: 200px;
     background-color: #0f0e2b;
     clip-path: polygon(100% 69%, 0 100%, 100% 100%);
   }

   .headerContacts::before {
     content: "";
     position: absolute;
     bottom: 0;
     left: 0;
     width: 100%;
     height: 200px;
     background-color: #ffffff;
     clip-path: polygon(100% 69%, 0 102%, 103% 100%);
   }
}
</style>
