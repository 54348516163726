const ProductsCategores = () =>
 import(
  /* webpackChunkName: "ProductsCategores" */ "../views/ProductsCategores.vue"
 );
const SharedHosting = () =>
 import(
  /* webpackChunkName: "shared-hosting" */ "../views/Hosting/SharedHosting.vue"
 );
const VirtualPrivateServer = () =>
 import(
  /* webpackChunkName: "VirtualPrivateServer" */ "../views/Hosting/VirtualPrivateServer.vue"
 );
const ComparePlans = () =>
 import(
  /* webpackChunkName: "ComparePlans */ "../views/ComparePlans/ComparePlans.vue"
 );

export const productsCategoresRoutes = [
 {
  path: "/products-categores",
  name: "productsCategores",
  component: ProductsCategores,
 },
 {
  path: "/shared-hosting",
  name: "SharedHosting",
  component: SharedHosting,
 },
 {
  path: "/virtual-private-server",
  name: "VirtualPrivateServer",
  component: VirtualPrivateServer,
 },
 {
  path: "/compare-plans",
  name: "ComparePlans",
  component: ComparePlans,
 },
];
