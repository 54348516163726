import Router from "vue-router";
/* import "../../middleware/auth";
import "../../middleware/log"; */

export class RouterModule {
 constructor(i18nModule) {
  this.router = null;
  this.name = "router";
  this.i18nModule = i18nModule;
 }
 install(Vue) {
  Vue.use(Router);

  this.router = new Router({
   mode: "history",
   base: process.env.BASE_URL,
   scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
   },
  });
 }
}
