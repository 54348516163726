export * from "./router";
export * from "./store";
export * from "./i18n";
export * from "./middleware";
export * from "./core";

/**
 * @typedef PluginsModuleClass
 * @type {object}
 * @property {import('vue').default} app - an Vue App.
 * @property {import('vue-router').Route} router - .
 * @property {import('vuex').Store<any>} store - .
 * @property {import('vue-i18n').default} i18n - .
 */

/**
 * PluginsModule class for save pointer plugin for use in modules
 *
 * @type {PluginsModuleClass}
 */
export class PluginsModule {
  static name = "plugins";

  static app;
  static i18n;
  static store;
  static router;

  /**
   *
   * @param {import('vue').default} app
   * @param {import('vue-router').Route} router
   * @param {import('vuex').Store<any>} store
   * @param {import('vue-i18n').default} i18n
   */
  static install(app, router, store, i18n) {
    this.app = app;
    this.router = router;
    this.store = store;
    this.i18n = i18n;
  }
}
