<template>
  <div id="testimonials">
    <b-container>
      <div class="main-title">
        <h2>WHAT OUR CLIENTS SAY</h2>
      </div>
      <b-row
        class="boxses-content"
        cols-xl="3"
        cols-lg="3"
        cols-md="2"
        cols-sm="1"
        cols="1"
      >
        <b-col>
          <div class="profile-circle">
            <i class="fas fa-user-tie"></i>
          </div>
          <blockquote>
            <p>
              <i class="fa quote fa-quote-right fa-sm pull-left"></i>
              <br />
              If you want a safe and reliable hosting that's not outstandingly
              overpriced, 1stVault is the way to go.
              <i class="fa quote fa-quote-right fa-sm"></i>
            </p>
            <small>abd alshafie</small>
          </blockquote>
        </b-col>
        <b-col>
          <div class="profile-circle">
            <i class="fas fa-user-tie"></i>
          </div>
          <blockquote>
            <p>
              <i class="fa quote fa-quote-right fa-sm pull-left"></i>
              <br />
              1stVault Cloud has made my life a ton easier I know I can rely on
              them whenever my site has any issues—which is rare..
              <i class="fa quote fa-quote-right fa-sm"></i>
            </p>
            <small>Tasneem</small>
          </blockquote></b-col
        >
        <b-col>
          <div class="profile-circle">
            <i class="fas fa-user-tie"></i>
          </div>
          <blockquote>
            <p>
              <i class="fa quote fa-quote-right fa-sm pull-left"></i>
              <br />
              I love that my hosting is handled by a team of cyber security
              experts. I never have to worry about my website's availability and
              privacy
              <i class="fa quote fa-quote-right fa-sm"></i>
            </p>
            <small>Mohammed alem</small>
          </blockquote></b-col
        >
      </b-row>
      <div class="carouselSlide">
        <ol class="indicators">
          <li></li>
          <li class="active"></li>
        </ol>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@700&display=swap");
#testimonials {
  position: relative;
  padding-top: 80px;
  padding-bottom: 50px;
  background: #fff;
  .main-title {
    color: #1c2746;
    font-size: 36px;
    font-weight: 700;
    font-family: "Overpass";
    text-align: center;
  }
  .boxses-content {
    margin-bottom: 40px;

    .profile-circle {
      display: flex;
      justify-content: center;
      .fa-user-tie {
        width: 80px;
        height: 80px;
        margin-bottom: -25px;
        border-radius: 100%;
        color: #253258;
        background: white;
      }
    }
    blockquote {
      text-align: left;
      border: none;
      font-size: 14px;
      line-height: 23px;
      background: #1c2746;
      padding: 20px;
      width: auto;
      margin: 0 auto;
      border-radius: 10px;
      p {
        i {
          color: #b4b8bc;
          text-align: center;
          opacity: 0.5;
          padding-left: 5px;
        }
      }
      small {
        color: #d4c6e7;
        font-size: 13px;
        padding-top: 15px;
      }
      small:before {
        content: "\2014 \00A0";
      }
    }
  }
  .carouselSlide {
    .indicators {
      text-align: center;
      display: flex;
      justify-content: center;
      li {
        list-style: none;
        background-color: #008aff;
        border: none;
        opacity: 0.8;
        width: 40px;
        height: 10px;
        margin-right: 3px;
        border-radius: 5px;
      }
      li.active {
        display: inline-block;
        width: 10px;
        height: 10px;
        text-indent: -999px;
        cursor: pointer;
        border-radius: 10px;
      }
    }
  }
}
#testimonials::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 200px;
  background-color: #0f0e2b;
  clip-path: polygon(100% 69%, 0 100%, 100% 100%);
}
</style>
