const Cart = () => import(/* webpackChunkName: "cart" */ "../views/Cart.vue");
const ConfgProduct = () =>
 import(/* webpackChunkName: "CongigProduct"*/ "../views/ConfgProduct.vue");
const ShoppingCartEmpty = () =>
 import(
  /* webpackChunkName: "ShoppingCartEmpty"*/ "../views/ShoppingCartEmpty.vue"
 );
const ConfgDomains = () =>
 import(/* webpackChunkName: "ConfDomains"*/ "../views/ConfgDomains.vue");
 const EditCartBeforeCheckout = () =>
 import(/* webpackChunkName: "EditCartBeforeCheckout"*/ "../views/EditCartBeforeCheckout.vue");


export const cartRoutes = [
 {
  path: "/cart",
  name: "cart",
  component: Cart,
  /*     meta: {
      // Hint: try to switch those two around to see
      // how this affects execution of the callbacks.
      middleware: [auth, log],
    }, */
 },
 {
  path: "/ConfgProduct",
  name: "ConfgProduct",
  component: ConfgProduct,
 },
 {
  path: "/ShoppingCartEmpty",
  name: "ShoppingCartEmpty",
  component: ShoppingCartEmpty,
 },
 {
  path: "/ConfgDomains",
  name: "ConfgDomains",
  component: ConfgDomains,
 },
 {
  path:"/EditCartBeforeCheckout",
  name:"EditCartBeforeCheckout",
  component:EditCartBeforeCheckout,

 },
];
