import axios from "axios";
//

import { PluginsModule } from "@/modules/plugins";
// import Vue from "vue";
// import swal from "sweetalert2";

const DEFAULT_API_TIMEOUT = 6000; // 6 seconds

// Set config defaults when creating the instance
const client = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/api",
  timeout: process.env.VUE_APP_API_TIMEOUT || DEFAULT_API_TIMEOUT,
});
client.defaults.withCredentials = true;
client.interceptors.request.use((request) => {
  // const token = PluginsModule.store.getters["client/authToken"];
  /*  const token = localStorage.getItem("token"); */
  const locale = PluginsModule.i18n.locale;

  /*   if (token) {
    request.headers.common["Authorization"] = `Bearer ${token}`;
  } */

  if (locale) {
    request.headers.common["Accept-Language"] = locale;
  }
  return request;
});

// response interceptor
// client.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const { status } = error.response;
//     if (status >= 500) {
//       // swal.fire({
//       //   type: "error",
//       //   title: PluginsModule.i18n.t("error_alert_title"),
//       //   text: PluginsModule.i18n.t("error_alert_text"),
//       //   reverseButtons: true,
//       //   confirmButtonText: PluginsModule.i18n.t("ok"),
//       //   cancelButtonText: PluginsModule.i18n.t("cancel"),
//       // });
//     }
//else if (status == 400) {
//   //  Bad Request
//   swal.fire({
//     type: "error",
//     title: Vue.i18n.translate("Bad Request"),
//     text: data ? data : "",
//     reverseButtons: true,
//     confirmButtonText: Vue.i18n.translate("ok"),
//     cancelButtonText: Vue.i18n.translate("cancel"),
//   });
// } else if (status == 413) {
//   // PAYLOAD TOO LARGE
//   swal.fire({
//     type: "warning",
//     title: Vue.i18n.translate("PAYLOAD_TOO_LARGE"),
//     text: Vue.i18n.translate("PAYLOAD_TOO_LARGE"),
//     reverseButtons: true,
//     confirmButtonText: Vue.i18n.translate("ok"),
//     cancelButtonText: Vue.i18n.translate("cancel"),
//   });
// } else if (status == 429) {
//   // Too Many Requests
//   swal.fire({
//     type: "warning",
//     title: Vue.i18n.translate("TOO_MANY_REQUESTS"),
//     text: Vue.i18n.translate("TOO_MANY_REQUESTS_DETAILS"),
//     reverseButtons: true,
//     confirmButtonText: Vue.i18n.translate("ok"),
//     cancelButtonText: Vue.i18n.translate("cancel"),
//   });
// } else if (status == 401) {
//   // swal({
//   //     type: 'warning',
//   //     title: app.$t('token_expired_alert_title'),
//   //     text: app.$t('token_expired_alert_text'),
//   //     reverseButtons: true,
//   //     confirmButtonText: app.$t('ok'),
//   //     cancelButtonText: app.$t('cancel')
//   // })
//   // .then((result) => {
//   // });
//   PluginsModule.store.dispatch("validateToken");
//   // or
//   PluginsModule.store.commit("client/authLogout");
//   PluginsModule.router.push({ name: "client.login" }).catch(() => {});
// } else if (!error.response) {
//   // error nerwork ot backend server dowm

//   const message =
//     err == "Error: Network Error"
//       ? "Network Error" // backend down
//       : "some thing went wrong, please try again later";

//   swal.fire({
//     type: "error",
//     title: Vue.i18n.translate("error"),
//     text: message,
//     reverseButtons: true,
//     confirmButtonText: Vue.i18n.translate("ok"),
//     cancelButtonText: Vue.i18n.translate("cancel"),
//   });
// }
//return Promise.reject(error);
//}
//);

export default client;

// Vue.use({
//   install(Vue) {
//     Vue.prototype.$client = client; // Now, you can do this.$client.get(...) on every component method
//   }
// });
