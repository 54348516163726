import { walletRoutes } from "./router/wallet-routes";
import { walletStore } from "./store";

export class WalletModule {
 constructor(router, store) {
  this.router = router;
  this.store = store;
  this.name = "wallet";
 }

 install(/*Vue*/) {
  // this.router.addRoutes(walletRoutes);
  for (let walletRoute of walletRoutes) {
   this.router.addRoute(walletRoute);
  }
  this.store.registerModule([this.name], walletStore);
 }
}
