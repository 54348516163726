<template>
 <div id="Hero">
  <b-container>
   <b-row>
    <b-col lg="6" md="12" sm="12">
     <h5>
      {{ $t("hero.brief") }}
     </h5>
     <p>
      Be one of our special customers to benefit from our Unique and Reliable DC
      solutions by the best professionals.
     </p>
     <br />
     <div class="features">
      <i class="far fa-check-circle"></i> <span>Unlimited Hosting</span>
     </div>
     <div class="features">
      <i class="far fa-check-circle"></i>
      <span> 24/7 Customer Support</span>
     </div>
     <div class="features">
      <i class="far fa-check-circle"></i> <span> 99.9% Uptime</span>
     </div>
     <div class="features">
      <i class="far fa-check-circle"></i> <span>Teir3 Data Center</span>
     </div>
     <div class="features">
      <i class="far fa-check-circle"></i>
      <span> Multiple Uplink Connections</span>
     </div>
     <div class="features">
      <i class="far fa-check-circle"></i>
      <span> Daily Backups</span>
     </div>
    </b-col>
    <b-col lg="6" md="12" sm="12">
     <img src="../assets/static-main-1.png" alt="" />
    </b-col>
   </b-row>
  </b-container>
 </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/styles/colors/_main-colors.scss";
@import "@/styles/fonts/_main-fonts.scss";
#Hero {
 padding-top: 130px;
 position: relative;
 background-color: #0e0b2b;

 h5 {
  font-size: 30px;
  font-weight: 600;
  margin: 20px 0 20px;
  color: #008aff;
  font-family: "Montserrat", sans-serif !important;
  letter-spacing: 1px;
 }
 p {
  font-size: 17px;
  line-height: 26px;
  font-family: "Montserrat", sans-serif !important;
  transition: 0.2s linear;
  color: blanchedalmond;
 }
 .features {
  margin: 0 0 7px;
  .fa-check-circle {
   font-size: 17px;
   color: gray;
  }
  span {
   font-size: 17px;
   color: blanchedalmond;
   font-family: "Montserrat", sans-serif !important;
  }
 }

 img {
  width: 100%;
  margin-bottom: 50px;
 }
}
#Hero::before {
 content: "";
 position: absolute;
 bottom: 0;
 left: 0;
 width: 100%;
 height: 200px;
 background-color: #ffffff;
 clip-path: polygon(100% 69%, 0 100%, 100% 100%);
}
:lang(ar) {
 #Hero {
  h5 {
   font-family: $lang-arabic-paragraph;
  }
 }
}
</style>
