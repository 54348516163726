import nextFactory from "./nextFactory";

export default (to, from, next, router, store) => {
  if (to.meta && to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
      store,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }
  next();
};

/** middleware role */
export { default as auth } from "./auth";
