<template>
  <div id="PrivacyPolicy">
 <Navigation/>
    <div class="headerPrivacyPolicy">
      <b-container>
        <b-row>
          <b-col lg="6" md="12">
            <p>1stVault.</p>
            <h2>Privacy Policy</h2>
          </b-col>
          <b-col lg="6" md="12"
            ><img src="../assets/Privacy-Policy-Banner-New.png" alt="about"
          /></b-col>
        </b-row>
      </b-container>
    </div>
    <div class="colorfulBoxesPrivacyPolicy">
      <b-container>
        <div class="title">
          <h2>PRIVACY POLICY</h2>
        </div>
        <b-row>
          <b-col>
            <div class="box-container">
              <h4>General information</h4>
              <p>
                This Privacy Policy governs the manner in which Proton Internet
                LLP collects, uses, maintains and discloses information
                collected from users (each, a "User") of the 1stVault Cloud
                website ("Site"). This privacy policy applies to the Site and
                all products and services offered by Proton Internet LLP.
              </p>
              <br />
              <h4>Personal identification information</h4>
              <p>
                We may collect personal identification information from Users in
                a variety of ways, including, but not limited to, when Users
                visit our site, place an order, fill out a form, and in
                connection with other activities, services, features or
                resources we make available on our Site. Users may be asked for,
                as appropriate, name, email address, mailing address, phone
                number, credit card information. Users may, however, visit our
                Site anonymously. We will collect personal identification
                information from Users only if they voluntarily submit such
                information to us. Users may always refuse to supply personal
                identification information, except when it may prevent them from
                engaging in certain Site related activities.
              </p>
              <br />
              <h4>How we use collected information</h4>
              <p>
                Proton Internet LLP may collect and use Users personal
                information for the following purposes: To improve customer
                service(information you provide helps us respond to your
                customer service requests and support needs more efficiently.)
                To run a promotion, contest, survey or other site feature
                (information you agreed to receive about topics we think will be
                of interest.) To send periodic emails (Proton Internet LLP may
                use the email address provided upon registering, to send Users
                information and updates pertaining to their order, billing,
                active services, abuse related inquiries and promotions.)
              </p>
              <br />
              <h4>Non-personal identification information</h4>
              <p>
                We may collect non-personal identification information about
                Users whenever they interact with our Site. The non-personal
                identification information may include but is not limited to the
                Users’ browser name, the type of computer and IP address, as
                well as technical information about Users’ means of connection
                to our Site, such as the operating system and the Internet
                service providers utilized.
              </p>
              <br />
              <h4>Web browser cookies</h4>
              <p>
                Our Site may use “cookies” to enhance our Users’ experience.
                Users’ web browser places cookies on their hard drive for
                record-keeping purposes and sometimes to track information about
                them. The user may choose to set their web browser to refuse
                cookies or to alert you when cookies are being sent. If they do
                so, note that some parts of the Site may not function properly.
              </p>
              <br />
              <h4>How we protect your information</h4>
              <p>
                We adopt appropriate data collection, storage and processing
                practices and security measures to protect against unauthorized
                access, alteration, disclosure or destruction of your personal
                information, username, password, transaction information and
                data stored on our Site. Sensitive and private data exchange
                between the Site and its Users happens over an SSL secured
                communication channel and is encrypted and protected with
                digital signatures.
              </p>
              <br />
              <h4>Changes to this privacy policy</h4>
              <p>
                Proton Internet LLP reserves the right to update this privacy
                policy at any time. When we do so, we will revise the Last
                revision date at the top of this page. We encourage Users to
                frequently check this page for any changes in order to stay
                informed about how we are helping to protect the personal
                information we collect. You acknowledge and agree that it is
                your responsibility to review this privacy policy periodically
                and keep aware of modifications.
              </p>
              <h4>Sharing your personal information</h4>
              <p>
                We do not sell, trade, or rent Users’ personal identification
                information to others. We may share generic aggregated
                demographic information not linked to any personal
                identification information regarding visitors and users with our
                business partners, trusted affiliates and advertisers for the
                purposes outlined above.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <Partners />
    <Footer />
  </div>
</template>

<script>

import Footer from "../components/Footer.vue";
import Partners from "../components/Partners.vue";
import Navigation from "../components/Navigation.vue";
export default {
  name: "PrivacyPolicy",
  components: { Footer, Partners, Navigation },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@700&display=swap");
#PrivacyPolicy {
  .headerPrivacyPolicy {
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #0e0b2b;
    h2 {
      padding: 0 0 30px 0;
      font-weight: 800;
      font-size: 40px;
      color: #008aff;
      font-family: "Montserrat", sans-serif !important;
      letter-spacing: 1px;
    }
    p {
      margin-top: 54px;
      font-size: 17px;
      line-height: 26px;
      font-family: "Montserrat", sans-serif !important;
      transition: 0.2s linear;
      color: blanchedalmond;
    }
    img {
      width: 100%;
    }
  }
  .headerPrivacyPolicy::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background-color: #ffffff;
    clip-path: polygon(100% 69%, 0 102%, 103% 100%);
  }
  .colorfulBoxesPrivacyPolicy {
    position: relative;
    background-color: #fff;
    padding: 70px 0 70px 0;
    .title {
      text-align: center;
      padding-bottom: 60px;
      h2 {
        color: #1c2746;
        font-size: 36px;
        font-weight: 700;
        font-family: "Overpass", sans-serif;
      }
    }
    .box-container {
      text-align: left;
      padding: 0 40px 50px;
      border-radius: 15px;
      margin: 0 15px;
      font-family: "Montserrat", sans-serif !important;
      h4 {
        margin-top: 0;
        font-family: "Overpass";
        font-size: 23px;
        font-weight: 600;
        margin-bottom: 15px;
        color: #1c2746;
      }
      p {
        color: #1c2746;
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
  .colorfulBoxesPrivacyPolicy::before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 200px;
    background-color: #0f0e2b;
    clip-path: polygon(100% 69%, 0 100%, 100% 100%);
  }
}
</style>
