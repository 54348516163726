<template>
  <div id="AboutUs">
   <Navigation/>
    <div class="headerAboutUs">
      <b-container>
        <b-row>
          <b-col lg="6" md="12">
            <h2>Who We Are</h2>
            <p>
              We at 1stVault Cloud always believe that web hosting should be fast,
              reliable, simple and affordable.
            </p>
            <button type="button">Get Started Now</button>
          </b-col>
          <b-col lg="6" md="12"
            ><img src="../assets/about.png" alt="about"
          /></b-col>
        </b-row>
      </b-container>
    </div>
    <div id="whyChooseUs">
      <b-container>
        <b-row>
          <div class="text-center title">
            <h2>WHY CHOOSE US</h2>
          </div>
          <b-col lg="4" md="6" sm="1">
            <div class="box-container">
              <i class="icon fa fa-gift"></i>
              <h4>Offering Reliable Hosting</h4>
              <p>
                Shara Cloud has been providing reliable, scalable and secure web
                hosting solutions for individuals and businesses of all sizes.
              </p>
            </div>
          </b-col>
          <b-col lg="4" md="6" sm="1">
            <div class="box-container">
              <i class="icon fa fa-globe"></i>
              <h4>Exceptional Web Solutions</h4>
              <p>
                Our mission is to consistently provide exceptional web-based
                solutions at an excellent value to a diversity of clients.
              </p>
            </div>
          </b-col>
          <b-col xl="4" lg="4" md="6" sm="1" >
            <div class="box-container">
              <i class="icon fa fa-headphones"></i>
              <h4>Helping Our Customers</h4>
              <p>
                Our goal is to provide web hosting services so that our
                customers will have no difficulties to manage their website.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="features">
      <b-container>
        <p>We Offer</p>
        <h2>FEATURES INCLUDED</h2>
        <b-row>
          <b-col lg="6" md="12" sm="12" class="container-col-boxes">
            <div class="features-box">
              <b-row>
                <b-col lg="2" class="container-icon">
                  <div class="container-icon-i">
                    <i class="fa fa-server pull-left icon-features"></i>
                  </div>
                </b-col>
                <b-col lg="10">
                  <div class="container-breaf">
                    <h4>Data Centers</h4>
                    <p>
                      We provide well equipped Data Centers with quality
                      resources to our customers.
                    </p>
                  </div></b-col
                >
              </b-row>
            </div>
            <div class="features-box">
              <b-row>
                <b-col lg="2" class="container-icon">
                  <div class="container-icon-i">
                    <i class="fa fa-headphones pull-left icon-features"></i>
                  </div>
                </b-col>
                <b-col lg="10">
                  <div class="container-breaf">
                    <h4>Reliable Customer Services</h4>
                    <p>
                      High reliability servers and network ensuring that your
                      machine is always connected and running round-the-clock.
                    </p>
                  </div></b-col
                >
              </b-row>
            </div>
            <div class="features-box">
              <b-row>
                <b-col lg="2" class="container-icon">
                  <div class="container-icon-i">
                    <i class="fa fa-thumbs-up pull-left icon-features"></i>
                  </div>
                </b-col>
                <b-col lg="10">
                  <div class="container-breaf">
                    <h4>Guarantee of uptime</h4>
                    <p>
                      1stVault Cloud provide guarantee of 99.95% network uptime .
                    </p>
                  </div></b-col
                >
              </b-row>
            </div>
          </b-col>
          <b-col lg="6" md="12" sm="12" class="container-col-boxes-tow">
            <div class="features-box">
              <b-row>
                <b-col lg="2" class="container-icon">
                  <div class="container-icon-i">
                    <i class="fa fa-lock pull-left icon-features"></i>
                  </div>
                </b-col>
                <b-col lg="10">
                  <div class="container-breaf">
                    <h4>DDOS Protection</h4>
                    <p>
                      We have enterprise DDoS protection available to protect
                      your servers against malicious DDoS attacks.
                    </p>
                  </div></b-col
                >
              </b-row>
            </div>
            <div class="features-box">
              <b-row>
                <b-col lg="2" class="container-icon">
                  <div class="container-icon-i">
                    <i class="fa fa-globe pull-left icon-features"></i>
                  </div>
                </b-col>
                <b-col lg="10">
                  <div class="container-breaf">
                    <h4>Domain Services</h4>
                    <p>
                      We provides fast Domain Name Registration and affordable
                      professional Web Hosting Solutions. Grow your business
                      without breaking your budget.
                    </p>
                  </div></b-col
                >
              </b-row>
            </div>
            <div class="features-box">
              <b-row>
                <b-col lg="2" class="container-icon">
                  <div class="container-icon-i">
                    <i class="fa fa-cog pull-left icon-features"></i>
                  </div>
                </b-col>
                <b-col lg="10">
                  <div class="container-breaf">
                    <h4>Full Customization</h4>
                    <p>
                      1stVault Cloud customize your server to meet your needs with
                      our enterprise-grade infrastructure.
                    </p>
                  </div></b-col
                >
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="services">
      <b-container>
        <div class="title-services">
          <h2>SaharaCloud Services</h2>
        </div>
        <b-row>
          <b-col lg="4" md="6" sm="12">
            <div class="box">
              <i class="fas fa-thumbs-up icon"></i>
              <h4>Best Service</h4>
              <p>
                Our mission is to attract and retain customers by providing Best
                in Class solutions and fostering a profitable, disciplined
                culture of safety, service, and trust.
              </p>
            </div>
          </b-col>
          <b-col lg="4" md="6" sm="12">
            <div class="box">
              <i class="fas fa-trophy icon"></i>
              <h4>Reputation</h4>
              <p>
                We have established a strong presence in the industry. Our
                award-winning services earn a the reputation for quality and
                excellence that few can rival.
              </p>
            </div>
          </b-col>
          <b-col lg="4" md="6" sm="12">
            <div class="box">
              <i class="fas fa-lock icon"></i>
              <h4>Safety & Security</h4>
              <p>
                Safety for our employees, the customers and the community we
                work with will always remain our primary focus in all the
                policies, procedures and programs..
              </p>
            </div>
          </b-col>
          <b-col lg="4" md="6" sm="12">
            <div class="box">
              <i class="fas fa-headphones icon"></i>
              <h4>24H SUPPORT</h4>
              <p>
                At SaharaCloud our specialists stand by 24/7 to ensure that you
                are completely satisfied.
              </p>
            </div>
          </b-col>
          <b-col lg="4" md="6" sm="12">
            <div class="box">
              <i class="fas fa-check-circle icon"></i>
              <h4>High Quality Hardware</h4>
              <p>
                We use the latest hardware solutions that are updated and
                maintained regularly.
              </p>
            </div>
          </b-col>
          <b-col lg="4" md="6" sm="12">
            <div class="box">
              <i class="fas fa-undo icon"></i>
              <h4>7 Day Money-back Guarantee</h4>
              <p>
                If you are unsatisfied with our services, we will return you all
                your money during first 7 days.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <Partners />
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import Partners from "../components/Partners.vue";
import Navigation from "../components/Navigation.vue";
export default {
  name: "AboutUs",
  components: {
    Footer,
    Partners,
    Navigation
},
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@700&display=swap");
#AboutUs {
  .headerAboutUs {
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #0e0b2b;
    h2 {
      padding: 50px 0 30px 0;
      font-size: 30px;
      font-weight: 600;
      color: #008aff;
      font-family: "Montserrat", sans-serif !important;
      letter-spacing: 1px;
    }
    p {
      margin-bottom: 50px;
      font-size: 17px;
      line-height: 26px;
      font-family: "Montserrat", sans-serif !important;
      transition: 0.2s linear;
      color: blanchedalmond;
    }
    button {
      line-height: 16px;
      margin-right: 10px;
      padding: 12px 35px 12px;
      border: 2px solid #8d3de2;
      border-radius: 5px;
      background-color: #8d3de2;
      color: blanchedalmond;
      text-align: center;
      font-family: "Open Sans", sans-ser;
    }
    img {
      width: 100%;
    }
  }
  #whyChooseUs {
    padding: 70px 0 70px 0;
    background-color: #fff;
    display: flex;
    .title {
      margin: 28px 0;
      h2 {
        font-size: 36px;
        font-family: "Overpass", sans-serif;
        color: #1c2746;
      }
    }
    .box-container {
      text-align: center;
      padding: 0 40px 50px;
      border-radius: 15px;
      margin: 0 15px;
      background: transparent;
      border: 2px solid #ccc;
      box-shadow: 10px 10px 0px #ccc;
      .icon {
        font-size: 65px;
        margin: 50px 0 30px;
        color: #1c2746;
      }
      h4 {
        color: #1c2746;
        font-family: "Overpass", sans-serif;
        font-size: 23px;
        font-weight: 600;
        margin-bottom: 15px;
      }
      p {
        color: #1c2746;
        font-size: 16px;
        line-height: 25px;
        font-family: "Montserrat", sans-serif !important;
      }
    }
  }
  .headerAboutUs::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background-color: #ffffff;
    clip-path: polygon(100% 69%, 0 100%, 100% 100%);
  }
  .features {
    padding: 50px 0 50px 0;
    background-color: #0e0b2b;
    p {
      font-size: 16px;
      color: #718096;
      font-family: "Montserrat", sans-serif !important;
      text-align: center;
      padding: 0;
      margin-bottom: 7px;
    }
    h2 {
      font-size: 36px;
      font-weight: 700;
      font-family: "Overpass", sans-serif;
      text-align: center;
      color: blanchedalmond;
      margin-bottom: 35px;
    }
    .container-col-boxes {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }
    .container-col-boxes-tow {
      padding-left: 35px;
    }

    .features-box {
      margin: 12px 0 0px 0;
      padding: 0;
      .container-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .container-icon-i {
        padding: 7px 15px 7px 15px;
        background-color: #0e0b2b;
        border-radius: 2px;
        border: 1px solid #1c2746;

        .icon-features {
          color: #ffbe3d;
          font-size: 29px;
        }
      }
      h4 {
        font-size: 22px;
        font-weight: 700;
        font-family: "Montserrat", sans-serif !important;
        color: blanchedalmond;
      }
      p {
        font-size: 16px;
        color: #718096;
        font-family: "Montserrat", sans-serif !important;
        text-align: left;
        padding: 0;
      }
    }
  }
  .services {
    position: relative;
    padding: 70px 0 70px 0;
    background-color: #fff;
    display: flex;
    .title-services {
      margin: 0 auto;
      padding-bottom: 60px;
      h2 {
        color: #1c2746;
        font-size: 42px;
        font-weight: bold;
        font-family: "Overpass";
        text-align: center;
      }
    }
    .box {
      border-radius: 2px 2px 10px 10px;
      text-align: center;
      margin: 0 0 40px 0;
      background: #19233f;
      border-top: 5px solid #ecf0f0;
      padding: 40px 30px;
      box-shadow: 0px 0px 20px #040917;
      .icon {
        color: #ecf0f0;
        font-size: 4em;
        margin-bottom: 32px;
        font-weight: 900;
      }
      h4 {
        color: #7bacf7;
        font-family: "Open Sans";
        margin-top: 0;
        font-weight: 600;
        font-size: 18px;
      }
      p {
        color: #fff;
        transition: 0.2s linear;
        font-family: "Open Sans";
        margin-bottom: 0;
      }
    }
  }
  .services::before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 200px;
    background-color: #0f0e2b;
    clip-path: polygon(100% 69%, 0 100%, 100% 100%);
  }
}
</style>
