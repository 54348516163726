<template>
 <div>
  <div id="FeaturesStyle">
   <b-container>
    <b-row cols-xl="2" cols-lg="2" cols-md="1" cols-sm="1" cols="1">
     <b-col>
      <div class="boxFeaures">
       <div class="list-features">
        <h2 class="ttile">Highly reliable service</h2>
        <ul>
         <li>
          <i class="far fa-check-circle pull-left"></i>

          <span>Three Power Solutions</span>
          <p>
           With two well-maintained generators, and two UPSs we ensure that the
           downtime due to power loss is zero.
          </p>
         </li>
         <li>
          <i class="far fa-check-circle pull-left"></i>
          <span>Multiple internet connections</span>
          <p>
           With two direct fiber connections, one wireless, and Vsat, your
           server will never be offline.
          </p>
         </li>
         <li>
          <i class="far fa-check-circle pull-left"></i>
          <span>Redundant storage</span>
          <p>
           With three copies, two drives, one offsite storage solution, your
           data will never be lost and will always be accessible.
          </p>
         </li>
         <li>
          <i class="far fa-check-circle pull-left"></i>
          <span>High security</span>
          <p>
           With an international level standard of security, we make sure that
           no physical breach could occur to our facility.
          </p>
         </li>
        </ul>
        <!-- <div class="btn-features">
                  <a href=""
                    >Get Started <i class="fas fa-long-arrow-alt-right"></i
                  ></a>
                </div> -->
       </div>
      </div>
     </b-col>
     <b-col>
      <div class="img-features">
       <img src="../assets/server-platform.png" alt="img-features" />
      </div>
     </b-col>
    </b-row>
   </b-container>
  </div>
  <!--  =============================================================== -->
  <div id="FeaturesStyleTow">
   <b-container>
    <b-row cols-xl="2" cols-lg="2" cols-md="1" cols-sm="1" cols="1">
     <b-col>
      <div class="img-features">
       <img src="../assets/powerful-dedicated-servers.png" alt="img-features" />
      </div>
     </b-col>
     <b-col>
      <div class="boxFeaures">
       <div class="list-features">
        <h2 class="ttile">To satisfy our customers</h2>
        <ul>
         <li>
          <i class="far fa-check-circle pull-left"></i>

          <span>24/7 customer support</span>
          <p>
           Our customers operate 24/7, and they demand high-quality services,
           thus we have set up a team to manage their requirements at any time.
           We are always one phone call away.
          </p>
         </li>
         <li>
          <i class="far fa-check-circle pull-left"></i>
          <span>Low latency</span>
          <p>
           One of the biggest advantages of our data center is that it is hosted
           locally; as a result, our customers enjoy low latency and high
           quality of service.
          </p>
         </li>
         <li>
          <i class="far fa-check-circle pull-left"></i>
          <span>High-speed connectivity</span>
          <p>
           Thanks to our fiber connection, we offer a high internet connection
           to our customers, through which they could access their servers
           reliably.
          </p>
         </li>
         <li>
          <i class="far fa-check-circle pull-left"></i>
          <span>Disaster recovery</span>
          <p>
           For supported software, we offer multi-site disaster recovery
           solutions, between Tripoli and Benghazi, or your server and our
           facility.
          </p>
         </li>
        </ul>
        <!-- <div class="btn-features">
                  <a href=""
                    >Get Started <i class="fas fa-long-arrow-alt-right"></i
                  ></a>
                </div> -->
       </div>
      </div>
     </b-col>
    </b-row>
   </b-container>
  </div>
  <!-- <div id="FeaturesStyle">
      <b-container>
        <b-row cols-xl="2" cols-lg="2" cols-md="1" cols-sm="1" cols="1">
          <b-col>
            <div class="boxFeaures">
              <div class="list-features">
                <h2 class="ttile">Powerful Server and Platform</h2>
                <ul>
                  <li>
                    <i class="far fa-check-circle pull-left"></i>

                    <span>Unlimited Hosting</span>
                    <p>
                      We grow with you and provide unlimited hosting for your
                      developing hosting needs.
                    </p>
                  </li>
                  <li>
                    <i class="far fa-check-circle pull-left"></i>
                    <span>Premium security</span>
                    <p>
                      With cyber security pros on your side, your website is
                      impenetrable..
                    </p>
                  </li>
                  <li>
                    <i class="far fa-check-circle pull-left"></i>
                    <span>Reliable, Low Latency</span>
                    <p>Our network offers low latency routes..</p>
                  </li>
                  <li>
                    <i class="far fa-check-circle pull-left"></i>
                    <span>Unlimited Hosting</span>
                    <p>
                      We grow with you and provide unlimited hosting for your
                      developing hosting needs.
                    </p>
                  </li>
                </ul>
                <div class="btn-features">
                  <a href=""
                    >Get Started <i class="fas fa-long-arrow-alt-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="img-features">
              <img src="../assets/server-platform.png" alt="img-features" />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div> -->
 </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#FeaturesStyle {
 background-color: #ecf0fc;
 padding: 90px 0 90px 0;
 .list-features {
  display: grid;
  background: #1c2746;
  padding: 30px 15px 10px 15px;
  border-radius: 10px;
  h2 {
   margin: 10px 0;
   font-size: 34px;
   font-weight: 700;
   font-family: "Overpass";
   color: #ecf0fc;
  }
  ul {
   li {
    list-style: none;
    margin: 20px 0;
    .fa-check-circle {
     width: 24px;
     height: 24px;
     border-radius: 100%;
     background: #243052;
     border-radius: 100%;
     color: #ffbe3d;
     margin: 0 10px 0 0;
    }
    span {
     font-size: 22px;
     font-weight: 600;
     margin: 20px 0 20px 5px;
     color: #ecf0fc;
     font-family: "Montserrat", sans-serif !important;
     letter-spacing: 1px;
    }
    p {
     font-size: 16px;
     color: #85bceb;
     transition: 0.2s linear;
     font-family: "Open Sans";
     margin-bottom: 0;
     padding-left: 47px;
     letter-spacing: 1px;
    }
   }
  }
  .btn-features {
   margin: 7px 0 35px 0;

   a {
    text-decoration: none;
    line-height: 16px;
    margin-right: 10px;
    padding: 12px 35px 12px;
    border: 2px solid #8d3de2;
    background-color: #8d3de2;
    color: blanchedalmond;
    text-align: center;
    border-radius: 3px;
    font-family: "Montserrat", sans-serif !important;
    .fa-long-arrow-alt-right {
     color: blanchedalmond;
     margin: 5px 5px 0 0;
     line-height: 21px;
    }
   }
   a:hover {
    background-color: #3f0c74;
    border: 2px solid #3f0c74;
   }
  }
 }
 .img-features {
  margin: 60px 0 0 37px;
  img {
   width: 100%;
  }
 }
}
/* ================================== */
#FeaturesStyleTow {
 background-color: #0f0e2b;
 padding: 90px 0 90px 0;
 .list-features {
  display: grid;
  background: #0f0e2b;
  padding: 30px 15px 10px 15px;
  border-radius: 10px;

  box-shadow: 0px 10px 15px #918f8f;
  h2 {
   margin: 10px 0;
   font-size: 34px;
   font-weight: 700;
   font-family: "Overpass";
   color: #ecf0fc;
  }
  ul {
   li {
    list-style: none;
    margin: 20px 0;
    .fa-check-circle {
     width: 24px;
     height: 24px;
     border-radius: 100%;
     background: #243052;
     border-radius: 100%;
     color: #ffbe3d;
     margin: 0 10px 0 0;
    }
    span {
     font-size: 22px;
     font-weight: 600;
     margin: 20px 0 20px 5px;
     color: #ecf0fc;
     font-family: "Montserrat", sans-serif !important;
     letter-spacing: 1px;
    }
    p {
     font-size: 16px;
     color: #85bceb;
     transition: 0.2s linear;
     font-family: "Open Sans";
     margin-bottom: 0;
     padding-left: 47px;
     letter-spacing: 1px;
    }
   }
  }
  .btn-features {
   margin: 7px 0 35px 0;

   a {
    text-decoration: none;
    line-height: 16px;
    margin-right: 10px;
    padding: 12px 35px 12px;
    border: 2px solid #8d3de2;
    background-color: #8d3de2;
    color: blanchedalmond;
    text-align: center;
    border-radius: 3px;
    font-family: "Montserrat", sans-serif !important;
    .fa-long-arrow-alt-right {
     color: blanchedalmond;
     margin: 5px 5px 0 0;
     line-height: 21px;
    }
   }
   a:hover {
    background-color: #3f0c74;
    border: 2px solid #3f0c74;
   }
  }
 }
 .img-features {
  margin: 40px 60px 0 0;
  img {
   width: 100%;
  }
 }
}
</style>
